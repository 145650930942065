@import '../utilities/fonts_var';
@import '../utilities/mixins';

// Typography style

body {
  @include default-font-size($size-body);
  font: {
    family: $base-font-family;
    weight: 400;
  }
}
