.stepper-root {
  &.vertical {
    background-color: $stepper-background;
    cursor: pointer;
  }

  .step-root {
    .step-label {
      cursor: pointer;

      .icon-container {
        margin-right: 18px;

        svg {
          border: 1px solid $stepper-muted;
          border-radius: 100%;
          color: $white;

          text {
            fill: $stepper-muted;
            font-size: 11px;
          }
        }
      }

      &.active,
      &.completed {
        .icon-container {
          svg {
            border: 0;
            color: $stepper-fill;

            text {
              fill: $white;
            }
          }
        }
      }

      &.error {
        .icon-container {
          svg {
            border: 0;
            color: $stepper-error;

            text {
              fill: $white;
            }
          }
        }
      }

      .label-container {
        .label {
          font-size: 16px;
          font-weight: 300;
        }
      }
    }
  }
}
