@import '../utilities/fonts_var';

// Fonts include

@for $i from 1 through length($base-font-data) {
  @font-face {
    font-family: $base-font-name;
    font-style: nth(map-get($base-font-data, $i), 6);
    font-weight: nth(map-get($base-font-data, $i), 5);
    src: url('#{$font-path}#{nth(map-get($base-font-data, $i), 1)}#{nth(map-get($base-font-data, $i), 2)}.woff2') format('woff2'),
      url('#{$font-path}#{nth(map-get($base-font-data, $i), 1)}#{nth(map-get($base-font-data, $i), 2)}.woff') format('woff'),
      url('#{$font-path}#{nth(map-get($base-font-data, $i), 1)}#{nth(map-get($base-font-data, $i), 2)}.svg#robotoblack') format('svg'),
      url('#{$font-path}#{nth(map-get($base-font-data, $i), 1)}#{nth(map-get($base-font-data, $i), 2)}.eot'),
      url('#{$font-path}#{nth(map-get($base-font-data, $i), 1)}#{nth(map-get($base-font-data, $i), 2)}.eot?#iefix')
        format('embedded-opentype');
  }
}

// Fonts include

@for $i from 1 through length($montserrat-font-data) {
  @font-face {
    font-family: $montserrat-font-name;
    font-style: nth(map-get($montserrat-font-data, $i), 6);
    font-weight: nth(map-get($montserrat-font-data, $i), 5);
    src: local(nth(map-get($montserrat-font-data, $i), 3)), local(nth(map-get($montserrat-font-data, $i), 4)),
      url('#{$font-path}#{nth(map-get($montserrat-font-data, $i), 1)}#{nth(map-get($montserrat-font-data, $i), 2)}.woff2') format('woff2'),
      url('#{$font-path}#{nth(map-get($montserrat-font-data, $i), 1)}#{nth(map-get($montserrat-font-data, $i), 2)}.woff') format('woff');
  }
}

//@for $i from 1 through length($sub-font-data) {
//  @font-face {
//    font-family: $sub-font-name;
//    font-style: nth(map-get($sub-font-data, $i), 6);
//    font-weight: nth(map-get($sub-font-data, $i), 5);
//    src: local(nth(map-get($sub-font-data, $i), 3)), local(nth(map-get($sub-font-data, $i), 4)),
//    url('#{$font-path}#{nth(map-get($sub-font-data, $i), 1)}#{nth(map-get($sub-font-data, $i), 2)}.woff2') format('woff2'),
//    url('#{$font-path}#{nth(map-get($sub-font-data, $i), 1)}#{nth(map-get($sub-font-data, $i), 2)}.woff') format('woff');
//  }
//}
