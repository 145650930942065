.main-distance-form {
  .distance-form {
    .form-content-wrapper {
      &.registration-fields-form {
        max-width: 100%;
        padding: 32px 20px 32px;

        .registration-fields-list {
          display: flex;
          flex-direction: column;

          .row {
            display: flex;
            flex-flow: row wrap;
            min-height: 50px;
            border-bottom: dashed 1px $white-7;
            color: $grey-1;

            .label-icon {
              display: flex;
              justify-content: left;
              align-items: center;
              flex-grow: 7;
            }

            &.disabled {
              .cell {
                color: rgba($grey-1, 0.26);
              }
            }

            &.headers {
              font-size: 12px;
              color: $grey-5;
              line-height: 50px;
            }

            .cell {
              line-height: 50px;

              &.label {
                flex-grow: 8;
                min-width: 150px;
                text-align: inherit;
              }

              &.selected {
                flex-grow: 2;
                min-width: 80px;
                max-width: 90px;
                margin-right: 32px;
              }

              &.required {
                flex-grow: 1;
                min-width: 50px;
                max-width: 60px;
                .hidden {
                  width: 60px;
                }
              }

              &.selected,
              &.required {
                text-align: center;

                .checkbox-field {
                  width: 48px;
                  margin: 0 auto;

                  .field-root {
                    .field-root {
                      margin: 0 auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
