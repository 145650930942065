.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .forgot-password {
    color: $login-button-1;
    font-size: 14px;
    letter-spacing: 0.23px;
  }
  .login-signup-box {
    align-self: flex-start;
    margin-top: 32px;
    &--text,
    &--link {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
    }
    &--text {
      color: $white;
    }
    &--link {
      color: $yellow-1;
      text-transform: capitalize;
      font-weight: 700;
    }
  }
  .MuiOutlinedInput-input:-webkit-autofill {
    box-shadow: 0 0 0 50px #282f35 inset;
    -webkit-box-shadow: 0 0 0 50px #282f35 inset;
    -webkit-text-fill-color: #a7adb7;
  }
}
