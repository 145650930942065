.main-distance-form {
  .distance-form {
    .form-content-wrapper {
      &.distance-medical-assistants-form {
        max-width: 583px;
        padding-top: 16px;
        .subtitle {
          text-align: center;
          color: $distance-main-title;
          font: {
            size: 14px;
          }
        }

        .delete-icon {
          cursor: pointer;
          height: 20px;

          .svg-fill {
            fill: $checkpoints-main-text;
          }

          &:hover {
            .svg-fill {
              opacity: 0.8;
            }
          }
        }

        .add-new {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 34px;
          margin-bottom: 26px;
          cursor: pointer;

          .icon {
            color: $orange-1;
            height: 24px;

            svg {
              width: 11px;
              height: 11px;
            }
          }

          .label {
            font-size: 14px;
            margin-left: 8px;
            color: $checkpoints-main-add-item;
            font-weight: 500;
            line-height: 24px;
            text-transform: uppercase;
          }
        }

        .option-title {
          letter-spacing: 0.5px;
          line-height: 1.75;
          font: {
            size: 16px;
          }
        }

        .checkbox-field {
          margin-top: 0;
        }

        .list-title {
          margin-top: 40px;
          margin-bottom: 12px;
          color: $grey-6;
          font: {
            size: 12px;
          }
        }

        .distance-medical-assistants-list {
          margin-bottom: 24px;
          .separator {
            margin-top: 0;

            height: 1px;
            background-color: #e0e0e0;
          }
          .distance-medical-assistant-item {
            height: 51px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            p {
              font-size: 16px;
            }
            span {
              min-width: 24px;
              min-height: 24px;
              svg {
                color: $grey-6;
                max-width: 18.87px;
                max-height: 18.87px;
              }
            }
          }
        }

        .btn-group {
          margin-top: 0;
        }
      }
    }
  }
}
