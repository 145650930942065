.terms-container {
  margin-top: 150px;
}

.terms-header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  margin-bottom: 42px;
  padding: 0 24px;
  z-index: 100;
  background-color: $header-background;

  .terms-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    background-color: $yellow-1;

    .logo {
      width: 24px;
      height: 18px;
    }
  }
}

.go-to-back-action {
  margin: 150px 0 24px 50px;
  &--label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #a8aeb8;
  }
}

.terms-main-content {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  padding: 0 24px;

  .terms-main-content-wrapper {
    width: 100%;
    max-width: 810px;
  }

  &--title {
    margin-bottom: 64px;
    text-align: center;
  }
  .terms-description {
    margin-bottom: 32px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  .terms-section {
    margin-bottom: 40px;
    &--title {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.15px;
      margin-bottom: 24px;
    }
    &--text,
    .terms-description {
      margin-bottom: 14px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .stars {
    text-align: center;
    margin-bottom: 30px;
  }
  .bold {
    font-weight: bold;
  }
  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
    font-size: 12px;
  }
  th {
    padding: 5px;
    background-color: #9964e3;
    color: #ffffff;
    font-weight: bold;
    font-size: 16px;
  }
  td {
    padding: 5px;
  }
}

.terms-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  padding: 0 24px;
  background-color: $footer-background;

  .terms-copyright {
    color: $footer-copyright;
    font-size: 12px;
    line-height: 47px;
    display: flex;
    justify-content: space-between;
  }
}

.main-wrapper .content-wrapper .main-content.without-footer {
  height: initial !important;
}
