.intercom-namespace {
  .intercom-launcher-frame {
    bottom: 80px !important;
  }

  .intercom-borderless-frame {
    bottom: 140px !important;
  }

  .intercom-app-launcher-enabled {
    .intercom-messenger-frame {
      height: calc(100% - 20px - 140px - 20px) !important;
      bottom: calc(20px + 140px) !important;
    }
  }
}
