@import '../utilities/variables';

html {
  position: relative;
  height: 100%;

  body {
    min-height: 100%;

    .menu-item {
      .select-label-options {
        margin-left: 5px;
      }
    }

    .MuiMenu-list {
      .country-nationality-li:nth-child(2) {
        border-bottom: 1px solid $grey-5;
      }
    }
  }
}

// General classes for project
.wrapper {
  width: 100%;
  min-height: 100vh;
  background-color: $white;
}

.paper-z-index {
  z-index: 9999;
}
