.filter-container {
  display: flex;
  flex-flow: column wrap;
  min-width: 250px;
  padding: 16px 12px;

  .MuiAutocomplete-endAdornment {
    position: absolute;
    top: calc(50% - 18px);
    right: 0;
  }
  .errors {
    color: $red-2;
  }

  .separate-from-to {
    display: flex;
    align-items: center;

    font-size: 16px;
  }

  .form-group {
    display: flex;
    align-content: center;
    justify-content: space-between;

    .country-filter {
      max-width: 260px;
    }

    .autocomplete-with-search {
      flex: 1;
    }

    .select-with-search {
      height: auto;
      .MuiSvgIcon-root {
        position: absolute;
        top: calc(50% - 15px);
        right: 0;
      }
      .form-label {
        transform: translate(0, 50px) scale(1);

        &[data-shrink='true'] {
          transform: translate(0, 17px) scale(1);
        }
      }

      .select-menu {
        width: 100%;
        margin-top: 0;
        box-shadow: none;

        .no-option {
          font-size: 14px;
        }

        .menu-item {
          font-size: 14px;

          &.active {
            background-color: $filter-select-active;
          }

          &::hover {
            background-color: $filter-select-active;
          }
        }
      }

      .field {
        div {
          overflow: hidden;
          p {
            white-space: nowrap;
          }
        }
      }

      &.open {
        height: 300px;
      }

      .form-label {
        transform: translate(0, 45px) scale(1);
      }

      .form-label[data-shrink='true'] {
        font-size: 12px;
        line-height: 16px;
        transform: translate(0, 10px) scale(1);
      }
    }

    .range-field {
      max-width: 100px;
    }
  }

  .filter-control {
    display: flex;
    flex-flow: row center;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 16px;

    .apply {
      color: $filter-apply;
      cursor: pointer;
    }

    .clear {
      color: $filter-clear;
      cursor: pointer;
    }
  }

  &.date-range-picker-filter {
    .filter-control {
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 24px;

      .apply {
        background-color: $yellow-1;
        text-transform: uppercase;
        color: $grey-1;
        padding: 8px 13px;
        border-radius: 4px;
        margin: 0 26px;
      }

      .clear {
        margin: 0 26px;
        text-transform: uppercase;
      }
    }
  }
  .filter-description {
    color: $grey-6;
    font-size: 1.1rem;
    margin-bottom: 16px;
  }
}

.tooltip-container {
  padding: 12px;
  font-size: 14px;
}
