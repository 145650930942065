.drawer {
  .svg-fill {
    fill: white !important;
  }
}

.main-sidebar {
  min-width: 193px;
  min-height: calc(100vh - 127px);
  background-color: $sidebar-background;
  overflow: hidden;

  .svg-fill {
    fill: currentColor;
  }

  .menu {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 127px);
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    .menu-item {
      position: relative;
      height: 50px;
      color: $sidebar-text;
      font-size: 12px;
      text-transform: uppercase;
      cursor: pointer;

      .icon {
        position: absolute;
        top: 13px;
        left: 12px;
        width: 24px;
        font-size: 24px;
      }

      .label {
        margin-left: 48px;
        line-height: 50px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .drop-down {
        position: absolute;
        top: 13px;
        right: 8px;
        width: 24px;
        color: $sidebar-caret;
      }

      &.caret-active {
        .drop-down {
          transform: rotate(180deg);
        }
      }

      &:hover {
        background-color: $sidebar-background-selected;
      }

      &.active {
        background-color: $sidebar-background-selected;
        color: $sidebar-selected;

        &:before {
          position: absolute;
          content: '';
          top: 1px;
          left: 0;
          width: 4px;
          height: 48px;
          background-color: $sidebar-selected;
        }
      }
    }

    .header-item {
      &.active {
        background-color: $sidebar-background-selected;
        color: $sidebar-text;

        &:before {
          content: none;
        }
      }
    }

    .sub-menu {
      height: 0;
      background-color: $sidebar-background;
      transition: height 0.3s, opacity 0.4s;

      .menu-item {
        text-transform: capitalize;
      }

      &.hidden {
        height: 0;
      }
    }

    .active {
      background-color: $sidebar-background-selected;

      &:before {
        content: none;
      }
    }
  }
}
