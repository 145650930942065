.main-race-form {
  width: 100%;

  .toolbar {
    display: flex;
    align-items: center;
    min-height: 65px;

    .nav-toolbar {
      margin-left: 24px;
    }
  }

  .race-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    width: 100%;

    .contact-emails {
      .icon-add {
        cursor: pointer;
      }

      .contact-email-block {
        position: relative;

        .icon {
          position: absolute;
          top: 20px;
          right: 0;
          fill: $main-icon-fill;

          &.icon-tooltip {
            top: 22px;
            right: 30px;
          }
        }
      }
    }
  }
}

// .form-content-wrapper {
.distance-form {
  .icon-field {
    .icon-label {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 160px;
      margin-bottom: 4px;
      border: solid 1px $races-upload-file-border;
      cursor: pointer;

      .upload-icon {
        align-self: center;
        width: 64px;
        height: 52px;
      }

      .main-upload-image-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 16px;
        color: $races-upload-file-title;
        font: {
          weight: 500;
          size: 16px;
        }

        .sub-title {
          font-size: 10px;
        }
      }

      .image-preview {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
      }
    }

    .cancel-icon {
      position: absolute;
      top: -25px;
      right: -30px;
      color: $races-cancel-icon;
      cursor: pointer;
    }

    .icon-input {
      height: 0;
      visibility: hidden;
    }

    .errors {
      color: $red-2;
      font-size: 12px;
    }
  }

  .form-fields-wrapper {
    max-width: 360px;
    margin: 0 auto;

    .form-control {
      margin-top: 7px;
    }

    .select-with-search {
      .select-menu {
        position: absolute;
        width: 100%;
        margin-top: 0;
        z-index: 100;

        .no-option {
          font-size: 14px;
        }

        .menu-item {
          font-size: 14px;

          &.active {
            background-color: $filter-select-active;
          }

          &::hover {
            background-color: $filter-select-active;
          }
        }
      }

      .field {
        div {
          overflow: hidden;
          p {
            white-space: nowrap;
            padding-bottom: 8px;
          }
        }
      }
    }

    .react-datepicker-wrapper {
      width: 100%;

      .react-datepicker__input-container {
        width: 100%;
      }
    }

    .text-editor {
      margin-top: 16px;

      .editor-wrapper {
        background-color: $text-editor-background;
      }

      h1 {
        font-size: 2em;
        font-weight: bold;
      }

      h2 {
        font-size: 1.5em;
        font-weight: bold;
      }

      h3 {
        font-size: 1.17em;
        font-weight: bold;
      }
    }

    .address-field-wrapper {
      position: relative;
      display: flex;
      justify-content: center;

      .location-icon {
        margin-right: 16px;
        line-height: 82px;
      }
    }

    .btn-group {
      margin-top: 12px;
      display: flex;
      justify-content: space-between;

      .cancel {
        min-width: 104px;
        border-radius: 2px;
        color: $main-icon-fill;
        font: {
          size: 14px;
          weight: 500;
        }
      }

      .submit {
        min-width: 104px;
        border-radius: 2px;
        background-color: $races-submit-background;
        color: $white;
        font: {
          size: 14px;
          weight: 500;
        }
      }
    }
  }
}
