.unavailable-service-container {
  .container {
    padding-top: 150px;
    font-family: 'Roboto', sans-serif;
  }
  .title {
    font-family: 'Roboto', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    text-transform: uppercase;
    color: #262e35;
    font-weight: 500;
  }

  .sub-title {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    padding-bottom: 85px;
    color: #262e35;
  }

  .main-img-container {
    margin-top: 85px;
    margin: 0 auto;
    text-align: center;
  }

  .links-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 85px;
  }

  .links {
    list-style: none;
    display: flex;
  }

  .link-item {
    margin-right: 24px;
  }
}
