.table-row {
  .label {
    cursor: pointer;
    color: $black-1;
    transition: opacity 400ms;
    text-decoration: underline;
  }
}

.acions-for-list {
  width: 65px;
  display: flex;
  justify-content: space-between;
  height: 30px;

  svg {
    cursor: pointer;
    color: $grey-6;
    width: 24px;
    height: 24px;
  }
}

.coupon-description {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.coupons-details {
  color: $grey-1;
  overflow: auto;

  .top-section {
    margin: 4px 20px 0;

    .header {
      border: 1px solid rgba(168, 174, 184, 0.24);
      border-radius: 4px;
      padding: 24px 16px;

      .header-top-line {
        display: flex;
        justify-content: space-between;

        .controls {
          display: flex;
          justify-content: center;

          .xls {
            margin-top: 6px;
            margin-right: 8px;
            cursor: pointer;
          }
          .delete-item {
            color: $red-8;
          }
        }

        .coupon-name {
          margin-bottom: 13px;

          .title {
            font-weight: 700;
          }
          .badge {
            margin-left: 24px;
            font-size: 12px;
            font-weight: 500;
            padding: 4px 12px;
            text-transform: uppercase;

            &.active {
              background: rgba(102, 187, 106, 0.12);
              color: $green-3;

              .dot {
                display: inline-block;
                width: 6px;
                height: 6px;
                background: $green-3;
                margin: 0 9px 1px 0;
                border-radius: 50%;
              }
            }

            &.inactive {
              background: rgba(255, 138, 101, 0.12);
              color: $red-5;

              .dot {
                display: inline-block;
                width: 6px;
                height: 6px;
                background: $red-5;
                margin: 0 9px 1px 0;
                border-radius: 50%;
              }
            }
          }
        }
      }

      .coupon-code-race-name {
        font-size: 16px;
        margin-bottom: 32px;
        .name {
          font-weight: 700;
        }
        .value {
          font-weight: 400;
        }
      }

      .general-information {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      .general-information-line {
        &.line1 {
          margin-bottom: 10px;
        }
        .name {
          font-size: 16px;
          font-weight: 700;
          color: #a8aeb8;
          margin-right: 8px;
        }
        .value {
          font-size: 16px;
          font-weight: 400;
          margin-right: 32px;
        }
      }
    }
  }

  .coupon-description {
    padding: 32px 24px;

    .heading {
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      margin-bottom: 17px;
    }

    .description {
      border: 1px solid rgba(168, 174, 184, 0.24);
      border-radius: 4px;
      padding: 24px 16px 60px;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 70px;
      white-space: normal;
      word-break: break-word;
      hyphens: auto;
      // p {
      // }
    }
  }
}
