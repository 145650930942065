.main-container-detail {
  .payments-detail {
    &.account-info-wrapper {
      margin-bottom: 24px;

      .body-wrapper {
        display: flex;
        justify-content: space-between;

        .account-info-body {
          flex-grow: 2;

          .info-item {
            font-weight: bold;
          }
        }

        .info-item-export {
          flex-grow: 1;
          align-items: center;

          .export-icon {
            width: 32px;
            height: 32px;
            cursor: pointer;
          }
        }
      }
    }

    &.account-info-list {
      width: 100%;
      min-height: 300px;

      &:first-child {
        margin-right: 24px;
      }

      &:only-child {
        margin-right: 0;
      }
    }

    .info-item-export {
      display: flex;
      justify-content: flex-end;
    }
  }

  .additional-info-container {
    display: flex;
    margin-bottom: 24px;
  }

  .payed-items {
    margin-bottom: 24px;

    .price-value {
      font-size: 16px;
      font-weight: bold;
    }

    .price-currency {
      font-size: 14px;
    }

    .cell-type {
      text-align: right;
    }

    .cell-name {
      min-width: 300px;
      max-width: 300px;
    }

    .payed-items-table {
      margin-bottom: 15px;

      .table-cell {
        &.cell-product {
          max-width: 600px;
        }
      }

      .info-table-header {
        .table-row {
          .table-cell {
            color: $grey-5;
            font-size: 12px;
          }
        }
      }

      .info-table-body {
        .table-row {
          &:nth-child(2n) {
            background: $grey-13;
          }
          .table-cell {
            border-bottom: 1px dashed $grey-14;
            color: $grey-1;
            font-size: 16px;

            &.cell-name,
            &.cell-product {
              white-space: normal;
              overflow: hidden;
            }
          }
        }
      }

      .custom-field-name {
        font-weight: 400;
        color: $grey-15;
        font-style: italic;
      }

      .custom-field-title {
        font-weight: 600;
      }
    }

    .info-body {
      background: $white;
      .total-price {
        margin-left: auto;

        .price-value {
          font-size: 20px;
        }
      }
    }
  }
}
