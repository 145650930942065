.distance-racefox-form {
  width: 100%;
  padding: 32px 24px;

  .distance-racefox-content {
    display: flex;
    color: $grey-1;

    .distance-racefox-about {
      padding: 32px 36px 26px 24px;
      background-color: $white-2;
      width: 50%;
      border: 1px solid rgba(38, 46, 53, 0.12);
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border-right: none;

      img {
        max-width: 131px;
      }
      h2 {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.15px;
        margin: 24px 0 16px 0;
      }
      p {
        font-size: 14px;
        line-height: 20px;
        a {
          color: #009b98;
          text-decoration: underline;
        }
      }
    }
    .distance-racefox-conditions {
      padding: 32px 24px 26px;
      width: 50%;
      border: 1px solid rgba(38, 46, 53, 0.12);
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-left: none;

      h2 {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.15px;
        margin-bottom: 29px;
      }
      li {
        font-size: 14px;
        line-height: 16px;
        display: flex;
        padding-bottom: 16px;

        > div {
          padding-right: 8px;
        }
      }
    }
  }

  .distance-racefox-field {
    margin-left: -10px;
    .label {
      font-size: 16px;
      color: $grey-1;
    }
  }
}
