.main-coupon-form {
  .form-content-wrapper {
    .form-control {
      margin-top: 7px;
    }
    .btn-group {
      margin-top: 24px;
    }
  }

  width: 456px;
  padding: 48px;

  h1 {
    font-size: 20px;
    font-weight: 500;
    color: $black;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 20px;
  }

  input {
    height: 24px;
    font-size: 16px;
    width: 100%;
  }

  .form-label {
    font-size: 16px;
  }

  .field-root {
    font-size: 16px;
  }

  .select-menu {
    position: absolute;
    width: 100%;
    margin-top: 0;
    z-index: 100;

    .no-option {
      font-size: 14px;
    }
  }

  .date-picker-group,
  .discount-type {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .date-picker-group {
    .coupon-date-from,
    .coupon-date-to {
      width: 43%;
    }
  }

  .discount-type {
    color: $grey-6;

    .checked {
      color: $black;
    }

    h2 {
      font-size: 12px;
      padding-bottom: 8px;
      width: 100%;
    }

    label {
      span {
        font-size: 16px;
      }
    }
  }

  .menu-item {
    font-size: 14px;

    &.active {
      background-color: $filter-select-active;
    }

    &::hover {
      background-color: $filter-select-active;
    }
  }

  .coupon-distances {
    width: 100%;
  }

  .coupon-btn {
    display: flex;
    justify-content: space-between;

    .submit {
      width: 104px;
      height: 36px;
      border-radius: 2px;
      background-color: $yellow-1;

      span {
        width: 37px;
        height: 16px;
        font-size: 14px;
        letter-spacing: 0.5px;
        text-align: center;
        color: $white;
      }
    }

    .cancel {
      width: 57px;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.5px;
      text-align: center;
      color: $grey-5;
    }
  }
}
