.chip-card {
  &.chip-card-root {
    padding: 6px 12px;
    background-color: $chip-main-backgroud;
    font-size: 14px;

    .chip-label {
      margin-right: 10px;
    }

    .chip-delete-icon {
      width: 18px;

      path {
        fill: $white;
      }
    }
  }

  &.clear-all-chip {
    &.chip-card-root {
      border: 2px solid $chip-main-backgroud;
      background-color: $white;

      .chip-label {
        margin-right: 0;
      }
    }
  }
}
