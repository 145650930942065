.main-wrapper {
  .content-wrapper {
    .main-content {
      .details-racers-lists {
        height: 100%;
        background-color: $main-layout-background;
        padding: 24px 24px 0;
        border-radius: 2px;
        overflow: auto;
        .content {
          // overflow: hidden;

          &.main-container-list {
            background-color: $white;

            &.racers-list {
              padding: 0;
              display: flex;
              flex-direction: column;
              // overflow: auto;

              .sticky-left {
                position: sticky;
                left: 0;
                // z-index: 1;

                .filters-toolbar {
                  margin: 16px;
                }
              }
              .component-list {
                display: flex;
                flex: 1 0 auto;
                flex-direction: column;

                .racer-no-content {
                  flex: 1;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 24px;
                  color: $grey-6;
                }
              }
            }

            // TOOLBAR
            .toolbar-container {
              padding: 15px;
              background-color: $white;
              display: flex;
              justify-content: space-between;

              .title {
                font-size: 20px;
              }

              .racer-toolbar {
                padding-bottom: 0px;

                .details {
                  max-width: 70%;

                  :before {
                    border: 0;
                  }

                  :after {
                    border: 0;
                  }

                  .field-root {
                    div {
                      display: flex;
                      align-items: center;
                      font-size: 24px;

                      .select-label-options {
                        margin-left: 20px;
                        display: block;
                        background-color: $yellow-1;
                        padding: 5px;
                        font-size: 14px;
                      }

                      &:before {
                        border: 0;
                      }

                      &:after {
                        border: 0;
                      }
                    }
                  }

                  .distance-details {
                    .distance-info {
                      color: $grey-5;
                      font-size: 14px;
                      display: flex;

                      .date {
                        width: 92px;
                        height: 16px;
                        text-transform: uppercase;
                        margin-right: 40px;
                      }

                      .location {
                        display: flex;
                      }
                    }
                  }

                  .distance-prices {
                    .prices-list {
                      display: flex;

                      .price-item {
                        margin-right: 24px;

                        .price-date {
                          font-size: 14px;
                          color: $grey-5;
                        }

                        .price-value {
                          font-size: 24px;

                          .price-sek {
                            margin-left: 5px;
                            color: $grey-5;
                          }
                        }
                      }
                    }
                  }
                }

                .buttons {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;

                  .export-buttons {
                    color: $grey-6;

                    .list-buttons {
                      display: flex;

                      .button-item {
                        min-width: 64px;
                        margin-right: 12px;
                        color: $grey-6;
                        text-align: center;
                        cursor: pointer;

                        &:last-child {
                          margin-right: 0;
                        }

                        .button-icon {
                          height: 24px;
                          margin: 13px 0 11px;
                        }

                        .button-title {
                          font-size: 12px;
                          font-weight: 500;
                          text-transform: uppercase;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.component-list.racers-list {
  padding: 0;
  width: 100%;
  overflow: initial;

  .table-root {
    border: none;
    box-shadow: none;
    overflow: auto;

    &.block-scroll {
      overflow: hidden;
    }

    table {
      width: 100%;
      table-layout: fixed;
      border-collapse: separate;

      .form-control {
        width: 100%;
        min-height: initial;
      }
    }

    th,
    td {
      padding: 5px;
    }

    td {
      border: 1px solid #ececece8;
      overflow-wrap: break-word;

      &:first-child {
        position: sticky;
        left: 0;
        background-color: #fff;
      }
    }

    th {
      width: 150px;
      border: 1px solid #3d3d3d24;
    }

    thead {
      position: relative;

      th {
        font-size: 12px;
        background-color: #e9e9e9;
        z-index: 1;
      }
    }

    tbody {
      tr {
        height: 68px;

        &.awaiting {
          background: $yellow-5;
        }
      }

      tr:nth-child(even) {
        background-color: rgba(169, 169, 169, 0.06);

        &.awaiting {
          background: $yellow-5;
        }
      }
    }

    .cell.custom-cell {
      width: 250px;
    }
  }

  .controls-block {
    display: flex;
    justify-content: center;

    .primary-icon {
      cursor: pointer;
      color: $grey-6;
    }
  }

  &.non-sticky {
    td {
      &:first-child {
        position: inherit;
      }
    }
  }
}

.controls-menu {
  .control-item {
    &.secondary {
      svg {
        color: $red-8;
      }

      span {
        color: $red-8;
      }
    }

    svg {
      color: $grey-6;
    }

    .text {
      padding: 0 32px;
      font-size: 16px;
      letter-spacing: 0.5px;
      color: $grey-1;
    }

    .squads {
      padding: 0;
      width: 168px;
    }
  }
}
