.react-tel-input {
  position: relative;
  width: 100%;
  max-height: 39px;

  .flag {
    &.none-country {
      width: 24px;
      height: 24px;

      background-size: contain;
      background: url('../../img/svg/icons/language.svg') no-repeat;
    }
  }

  input {
    &.form-control {
      height: 24px;
      padding: 6px 7px 6px 52px;
      background-color: transparent;
      border: 0;
      outline: none;
      box-sizing: content-box;
    }

    &.search-box {
      height: 36px;
    }
  }

  .selected-flag {
    position: absolute;
    display: flex;
    align-items: center;
    cursor: pointer;

    top: 0;
    left: 0;
    z-index: 10;

    height: 24px;
    width: 52px;
    padding: 6px 0 7px;

    box-sizing: content-box;
    background-color: white;

    &::before {
      position: absolute;
      content: '';
      right: 12px;
      top: 16px;

      height: 5px;
      width: 5px;
      border: 5px solid transparent;
      border-top-color: $grey-6;
    }

    &.open-dropdown {
      border-top-color: transparent;
      border-bottom-color: $grey-6;
    }
  }

  .country-list {
    position: absolute;
    z-index: 10;
    overflow-y: scroll;

    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    height: 300px;
    width: 100%;

    li {
      padding: 8px 14px;
      transition: background-color 200ms;

      &:hover {
        background-color: $yellow-3;
      }
    }

    .flag {
      display: inline-block;
      margin-right: 10px;
    }
  }
}

.form-label {
  &.phone-label {
    margin-left: 52px;
    padding-top: 3px;
  }
}
