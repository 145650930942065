.main-distance-form {
  width: 100%;
  padding: 0 24px 0;

  .toolbar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: $white-2;
    z-index: 10;
  }

  .form-wrap {
    display: flex;
    width: 100%;
    height: calc(100% - 64px);
    overflow: initial;
  }

  .distance-stepper {
    min-width: 30%;
    margin-right: 24px;
    padding: 32px 0 72px 16px;
    background-color: $distance-main-background;
    overflow-x: hidden;
    overflow-y: auto;

    .stepper-root {
      padding: 0;
      background-color: $distance-main-background;
    }
  }

  .distance-form {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 32px;
    padding-bottom: 32px;
    width: 100%;

    background-color: $distance-main-background;
    overflow-x: hidden;
    overflow-y: scroll;

    .step-title {
      color: $distance-main-title;
      text-transform: uppercase;
      font: {
        size: 16px;
        weight: 500;
      }
    }

    .form-content-wrapper {
      width: 100%;
      max-width: 440px;
      margin: 0 auto;
      padding-top: 24px;
      padding-bottom: 64px;

      .form-control {
        margin-top: 7px;
      }

      .select-with-search {
        .select-menu {
          position: absolute;
          width: 100%;
          margin-top: 0;
          z-index: 100;

          .no-option {
            font-size: 14px;
          }

          .menu-item {
            font-size: 14px;

            &.active {
              background-color: $filter-select-active;
            }

            &::hover {
              background-color: $filter-select-active;
            }
          }
        }

        .field {
          max-height: 38px;
          div {
            overflow: hidden;
            p {
              white-space: nowrap;
            }
          }
        }
      }

      .react-datepicker-wrapper {
        width: 100%;

        .react-datepicker__input-container {
          width: 100%;
        }
      }

      .address-field-wrapper {
        position: relative;
        display: flex;
        justify-content: center;

        .location-icon {
          margin-right: 16px;
          line-height: 82px;
        }
      }

      .date-picker-wrapper {
        display: flex;
        justify-content: center;

        .datepicker-icon {
          margin-right: 16px;
          line-height: 82px;
        }
      }

      .checkbox-field,
      .switch-field {
        .field-root {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          margin-right: 0;
          margin-left: 0;
        }

        label {
          &.field-root {
            width: 100%;
          }
        }

        .label {
          font-size: 16px;
        }

        &.muted {
          .label {
            color: $distance-muted-label;
          }
        }
      }
    }
    .controls {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
      .left-btn-group {
        display: flex;
        justify-content: flex-start;
      }
      .right-btn-group {
        display: flex;
        justify-content: flex-end;
      }
      .secondary {
        font-size: 14px;
        color: $grey-1;
      }
      .continue {
        min-width: 104px;
        border-radius: 2px;
        background-color: $distance-continue-background;
        color: $grey-1;
        font: {
          size: 14px;
          weight: 500;
        }

        &.disabled {
          background-color: $distance-continue-disabled;
        }
      }
      .cancel {
        margin-right: 27px;
        min-width: 73px;
        border-radius: 2px;
        color: $grey-1;
        font: {
          size: 14px;
          weight: 500;
        }
      }
    }

    .separator {
      width: 100%;
      height: 4px;
      margin-top: 16px;
      background-color: $distance-separator;
    }

    .field-group {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      width: 100%;

      & > div:first-child {
        margin-right: 12px;
      }

      & > div:last-child {
        margin-left: 12px;
      }

      .react-datepicker-popper {
        div {
          max-width: none;
        }
      }
    }
  }
}

.distance-form-scroll {
  overflow: scroll !important;
}
