@import 'variables';

@mixin make-font-size($font-size) {
  // If $font-size is a list, include both font-size and line-height
  @if type-of($font-size) == 'list' {
    font-size: nth($font-size, 1);

    @if (length($font-size) > 1) {
      line-height: nth($font-size, 2);
    }
  } @else {
    font-size: $font-size;
  }
}

@mixin make-margin($margin) {
  @if type-of($margin) == 'list' {
    @if (length($margin) > 2) {
      margin-top: (nth($margin, 3) / nth($margin, 1)) * 1.5em;
      margin-bottom: (nth($margin, 3) / nth($margin, 1)) * 0.5em;
    }
  }
}

@mixin default-font-size($fs-map, $breakpoints: $screen-breakpoints) {
  @each $breakpoint, $font-size in $fs-map {
    @if $breakpoint == null {
      @include make-font-size($font-size);
    } @else {
      // If $font-size is a key that exists in $breakpoints, use the value
      @if map-has-key($breakpoints, $breakpoint) {
        $breakpoint: map-get($breakpoints, $breakpoint);
      }

      @media (min-width: $breakpoint) {
        @include make-font-size($font-size);
      }
    }
  }
}

@mixin default-margin($m-map, $breakpoints: $screen-breakpoints) {
  @each $breakpoint, $margin in $m-map {
    @if $breakpoint == null {
      @include make-margin($margin);
    } @else {
      // If $margin is a key that exists in $breakpoints, use the value
      @if map-has-key($breakpoints, $breakpoint) {
        $breakpoint: map-get($breakpoints, $breakpoint);
      }

      @media (min-width: $breakpoint) {
        @include make-margin($margin);
      }
    }
  }
}

$num-of-tabs: 5;

@mixin tabs($num-of-tabs: 5) {
  @for $i from 1 through $num-of-tabs {
    &:nth-of-type(#{$i}) {
      &:checked {
        ~ .slide {
          left: calc((100% / #{$num-of-tabs}) * #{$i - 1});
        }
      }
    }
  }
}
