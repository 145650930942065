.main-wrapper {
  position: relative;
  min-height: 100vh;
  overflow: hidden;

  .content-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: calc(100vh - 127px);
    overflow: hidden;

    .main-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: calc(100vh - 127px);
      overflow: hidden;
      position: relative;

      &.without-footer {
        height: calc(100vh - 80px);
      }

      .content {
        height: 100%;
        background-color: $main-layout-background;
        overflow: auto;

        &.main-distance-form {
          overflow: initial;
        }
      }
    }
  }
}
