.menu-card {
  transform: translate(-10px, 75px);
}

.menu-card-item {
  min-width: 260px;
  width: 100%;

  p {
    font-size: 16px;
  }
}

.menu-card-text {
  text-transform: uppercase;
  font-size: 14px;
}

.menu-card-delete {
  min-width: 260px;
  width: 100%;

  p {
    color: $red-8;
    font-size: 16px;
  }
}

.popup-title {
  padding-bottom: 12px !important;

  h2 {
    font-size: 20px;
    color: $grey-1;
    line-height: 1.2;
  }
}
.race-image {
  width: 310px;
}

.popup-text {
  border: 1px solid $grey-11;
  margin: 0 20px !important;
  padding: 12px !important;
  max-height: 250px;

  p {
    white-space: pre-line;
    font-size: 16px;
    color: $grey-1;
    word-break: break-all;
  }
}

.popup-body {
  padding-bottom: 5px !important;
  p {
    padding-bottom: 15px;
    font-size: 16px;
  }
}

.message-popup {
  h2 {
    font-size: 20px;
  }

  .message-body {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .message-content {
    padding-bottom: 4px;
  }

  .message-actions {
    margin: 8px 22px;
  }

  &:focus {
    border-color: grey;
  }

  .message-text {
    font-size: 16px;

    textarea {
      max-height: 70px;
      height: 100%;
      font-size: 16px;
      line-height: 1.15;
    }
    label {
      color: gray;
      font-size: 14px;
    }
  }

  .message-submit {
    color: $orange-1;
    font-size: 14px;
    letter-spacing: 0.5px;
  }

  .message-cancel {
    color: gray;
    font-size: 14px;
    letter-spacing: 0.5px;
    margin-right: 22px;
  }
}

.menu-card-delete {
  min-width: 260px;
  width: 100%;

  p {
    font-size: 16px;
    color: $red-8;
  }
}

.main-wrapper {
  .content-wrapper {
    .main-content {
      .content {
        &.races-detail {
          overflow: scroll;
        }
      }
    }
  }
}

.races-detail {
  .toolbar-wrapper {
    //max-height: 40%;

    .navbar {
      display: flex;
      align-items: center;
      min-height: 65px;
      padding-right: 12px;
      padding-left: 24px;
    }

    .toolbar {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      min-height: 109px;
      padding: 0 16px;
      background-color: $race-detail-toolbar-background;

      .main-info {
        display: flex;
        flex: 1;
        min-width: 0;
        flex-flow: column nowrap;

        .location-info {
          display: flex;
          align-items: center;
          color: $races-detail-muted-text;
          font-size: 14px;
          letter-spacing: 0.2px;
          line-height: 16px;
          gap: 16px;
          .item {
            display: flex;
            align-items: center;
            gap: 4px;
          }
          .date {
            text-transform: uppercase;
          }

          .location-icon {
            display: flex;
            width: 24px;
            height: 24px;
            justify-content: center;
            align-items: center;

            div {
              max-height: 21px;
            }

            .svg-fill {
              fill: $races-detail-muted-text;
            }
          }
        }

        .logo svg {
          width: 60px;
          height: 60px;
        }

        .info {
          display: flex;
          align-items: flex-start;

          .title {
            margin-top: 16px;
            margin-bottom: 8px;
            color: $races-detail-header;
            font-size: 36px;
            font-weight: 700;
            letter-spacing: 0.2px;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
          }
        }
      }

      .toolbox {
        display: flex;
        margin: 16px;
        align-self: flex-start;
        gap: 16px;
        .action-button {
          padding: 6px 16px;
          white-space: nowrap;
        }
        .group-action {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-end;
          min-width: 64px;
          max-height: 50px;
          margin-right: 16px;
          cursor: pointer;
          &.menu {
            height: 50px;
            justify-content: flex-start;
          }

          .settings {
            display: flex;
            flex-direction: column;
          }

          .icon {
            min-height: 48px;
            width: 48px;
            position: absolute;
            top: -17px;
            justify-content: center;
            align-items: center;
            display: flex;

            div {
              height: 24px;
              width: 24px;
              justify-content: center;
              align-items: center;
              display: flex;
            }

            &.delete {
              .svg-fill {
                fill: $races-detail-muted-text2;
              }
            }

            &.duplicate {
              .svg-fill {
                fill: $races-detail-muted-text2;
              }
            }

            &.publish {
              .svg-fill {
                fill: $races-detail-muted-text2;
              }
            }

            &.active {
              .svg-fill {
                fill: $yellow-1;
              }
            }
          }

          &.action-publish {
            position: relative;
            text-transform: uppercase;
            font-size: 14px;
            white-space: nowrap;

            .switch-action {
              position: absolute;
              top: -13px;
            }
          }
        }

        .icon-button-settings {
          padding: 3px;
          color: inherit;
          &:hover {
            background: none;
          }
        }
      }

      .draft-tools {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        margin-top: 24px;
        margin-bottom: 14px;

        .draft-tooltip {
          .icon {
            margin-left: 6px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .draft-button {
    &.approve {
      background: $green-3;

      &:hover {
        background: $green-4;
      }
    }

    &.send-approve {
      min-width: 193px;
      white-space: nowrap;
      background: $green-3;

      &:hover {
        background: $green-4;
      }
    }

    &.send-approve-disable {
      min-width: 193px;
      white-space: nowrap;
      background: $grey-9;
      color: $grey-10;
    }

    &.reject {
      background: $red-5;

      &:hover {
        background: $red-6;
      }
    }
  }

  .race-content {
    display: flex;
    width: 100%;
    padding: 16px;

    .info-body {
      width: 60%;
      padding-right: 24px;
      word-wrap: break-word;

      h1,
      h2,
      h3,
      strong {
        font-weight: bold;
      }

      h1 {
        font-size: 2em;
      }

      h2 {
        font-size: 1.5em;
      }

      h3 {
        font-size: 1.17em;
      }
      .race-title {
        margin-top: 28px;
      }

      .race-desc-title {
        margin: 8px 0;
        text-transform: capitalize;

        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #262e35;
        opacity: 0.5;
      }

      .description {
        margin-bottom: 24px;
        font-size: 16px;

        iframe {
          width: 100%;
        }
      }
    }
    .distances-list {
      background-color: $white-5;
      padding: 12px 8px;
      width: 40%;

      .add-new-distance {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 240px;
        border: solid 1px $races-detail-add-new-border;
        cursor: pointer;

        .icon {
          margin-bottom: 16px;
        }

        .action-title {
          color: $races-detail-muted-text;
          font-size: 20px;
          font-weight: 500;
          line-height: 1;
          text-transform: uppercase;
        }

        .sub-title {
          color: $races-detail-muted-text;
          font-size: 12px;
          line-height: 20px;
        }
      }

      .add-new-distance-small {
        display: flex;
        justify-content: center;
        position: relative;
        margin-bottom: 12px;

        .title {
          font-weight: 700;
          font-size: 32px;
          line-height: 38px;
          letter-spacing: 0.2px;
          color: $grey-1;
        }

        .button {
          position: absolute;
          right: 0;
          top: 5px;
          font-size: 12px;
          padding: 2px 4px 2px 8px;
          .icon {
            margin-left: 4px;
          }
        }
      }

      .dragging {
        background: $white-2;
        border: 1px solid $races-detail-item-border;
        box-shadow: 0 3px 6px 0 $race-distance-dragging-shadow;
      }
      .distance-dnd {
        margin-top: 8px;
      }

      .distance-item {
        background: $white-2;
        display: grid;
        width: 100%;
        height: 100%;
        grid-template-areas:
          'handle title actions'
          'handle race-length epmty'
          'handle race-info participants';
        //grid-template-areas:
        grid-template-columns: 21px minmax(0, 1fr) minmax(124px, 144px);
        grid-template-rows: minmax(0, 1fr) minmax(24px, auto) minmax(0, auto);
        grid-column-gap: 12px;

        $bottom-line-padding: 0 0 9px 0;

        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
        border-radius: 4px;

        .info-text {
          text-transform: uppercase;
          white-space: pre;
          font-size: 14px;
          display: flex;
          align-items: center;
          height: 21px;
        }

        .distance-drag-handle {
          grid-area: handle;
          background: rgba($grey-16, 0.42);
          display: flex;
          align-items: center;
          justify-content: center;

          .distance-drag-icon {
            svg {
              width: 15px;
            }
          }
        }

        .race-info {
          display: flex;
          align-items: center;
          grid-area: race-info;
          color: $grey-5;
          padding: 0 0 4px 0;
          flex-wrap: wrap;
          .dot {
            margin: 0 11px;
            font-size: 26px;
          }
        }

        .main-title {
          grid-area: title;
          padding-top: 18px;
          color: $races-detail-header;
          font-size: 16px;
          font-weight: 700;
          word-break: break-word;
          a {
            color: inherit;
          }
        }

        .stats {
          display: flex;
          grid-area: race-length;
          align-items: center;

          .race-length {
            text-align: center;
            color: $races-detail-header;
            font-size: 16px;
            font-weight: 400;
          }
          .distance-length-meters {
            margin-right: 6px;
            font-size: 14px;
          }
        }

        .participants {
          grid-area: participants;
          display: flex;
          color: $grey-5;
          justify-content: flex-end;
          align-items: center;
          padding: 6px 8px 9px 0;

          .info-block {
            display: flex;
            align-items: center;
            background: $white-5;
            border-radius: 8px;
            padding: 0 4px;
            gap: 4px;
          }
          .info-text {
            margin-right: 0;
            padding: 2px 0;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
          }

          .icon {
            display: flex;
            align-items: center;
          }
        }

        .action-group {
          grid-area: actions;
          padding-top: 12px;
          padding-right: 12px;
          text-align: center;
          gap: 16px;
          justify-content: flex-end;

          .settings {
            display: flex;
            justify-content: center;
          }

          .icon-button-settings {
            padding: 0;
            height: 30px;
            color: $races-detail-muted-text2;

            svg {
              font-size: 24px;
            }

            &:hover {
              color: $grey-1;
              background: none;
            }
          }
        }
      }
    }
  }
  .icon-button-distance-action {
    padding: 0;
    height: 20px;
    width: 20px;
    color: $races-detail-muted-text2;

    svg {
      font-size: 24px;
    }

    &:hover {
      color: $grey-1;
      background: none;
    }
  }

  .icon-button-edit {
    padding: 0;
    height: 32px;
    width: 32px;
    background: $yellow-1;
    &:hover {
      background-color: rgb(174, 145, 26);
    }
  }

  .icon-button-update {
    margin: 0 0 20px 20px;
    padding: 0;
    height: 30px;
    color: $races-detail-muted-text2;

    svg {
      font-size: 24px;
    }

    &:hover {
      color: $grey-1;
      background: none;
    }
  }
}
