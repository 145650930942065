.react-datepicker-popper {
  z-index: 100 !important;
}

#menu-active {
  .menu-item {
    font-size: 16px;
  }
}

.main-layout {
  .form-control {
    .text-editor-label {
      font-size: 12px;
      color: $grey-6;

      position: absolute;
      z-index: 2;
      top: -11px;
      background: white;
      padding: 0 4px;
      margin-left: 8px;
    }
    .editor-without-border {
      border: none !important;
    }
    .editor-border {
      border: 1px solid #cbcbcb;
      border-radius: 4px;
      padding: 4px 0;
      position: relative;
      &:hover {
        border-color: black;
      }
    }
    font: {
      size: 16px;
    }

    &.select {
      .field-root {
        font-size: 16px;
      }

      .form-label {
        top: -6px;
      }
    }

    .form-label {
      color: $main-layout-text-color;
      font: {
        size: inherit;
      }

      &[data-shrink='true'] {
        font-size: 11px;
        line-height: 16px;
        text-transform: uppercase;
      }
    }

    .field {
      height: initial;
      font: {
        size: 16px;
      }
      line-height: 24px;
    }

    .MuiFormLabel-focused-11 {
      color: $auth-text !important;
    }

    .underline {
      &::before {
        border-color: $main-layout-border !important;
      }

      &::after {
        border-color: $main-layout-border;
      }
    }

    .filled-underline {
      &::before {
        border-color: $main-layout-border !important;
      }

      &::after {
        border-color: $main-layout-border;
      }
    }

    .focused {
      &::after {
        border-color: $auth-text !important;
      }
    }

    .errors {
      font: {
        size: 12px;
      }
    }

    .tooltip-text {
      font-size: 10px;
    }

    &.error {
      .form-label {
        color: $main-layout-errors;
      }

      .underline {
        &::before {
          border-color: $main-layout-errors !important;
        }

        &::after {
          border-color: $main-layout-errors !important;
        }
      }

      .filled-underline {
        &::before {
          border-color: $main-layout-errors !important;
        }

        &::after {
          border-color: $main-layout-errors;
        }
      }

      .focused {
        &::after {
          border-color: $main-layout-errors !important;
        }
      }
    }

    .react-datepicker-wrapper {
      display: block;

      .react-datepicker__input-container {
        display: block;
      }
    }

    &.input-mask {
      .field-root {
        input {
          &::placeholder {
            text-overflow: ellipsis !important;
            color: black !important;
            opacity: 0.3 !important;
          }
        }
      }
    }
  }

  .checkbox-field {
    margin-top: 16px;

    .field-root {
      color: $grey-6;

      &.checked {
        color: $yellow-1;
      }

      &.disabled {
        .field-root {
          color: rgba($grey-1, 0.26);
        }
      }

      .label {
        font-size: 14px;
      }
    }
  }

  .switch-field {
    position: relative;
    display: flex;
    margin-top: 16px;
    .switch-label {
      color: $grey-6;
    }
    .tooltip-icon {
      display: flex;
      align-items: center;
      cursor: pointer;

      margin-right: 5px;
      margin-left: 8px;

      svg {
        height: 24px;
        width: 24px;
      }
    }

    label {
      width: 100%;
    }

    &.muted {
      .label {
        color: $distance-muted-label;
      }
    }

    .field-root {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      margin-right: 0;
      margin-left: 0;

      .switch-base {
        .label {
          font-size: 14px;
        }

        &.checked {
          color: $white-6;

          .icon {
            color: $yellow-1;
          }
        }
      }

      .bar {
        background-color: $grey-6;
        opacity: 1;

        &.active {
          background-color: $yellow-1;
          opacity: 0.5;
        }
      }
    }
  }
}

.switch-popover {
  font-size: 12px !important;
  max-width: 400px !important;
}
.with-banner {
  height: calc(100% - 50px) !important;
}

.price-popover {
  background: rgba($black, 0.5);

  .close-icon-wrapper {
    display: flex;
    justify-content: end;
    padding: 22px 22px 0 0;

    svg {
      fill: $grey-6;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
