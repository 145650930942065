.main-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  background-color: $header-background;

  .logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;

    height: 100%;

    .logo {
      width: 147px;
      height: 32px;
    }
  }

  .toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 24px;
    color: $header-text;

    .logout {
      cursor: pointer;
    }

    .user-profile {
      display: flex;
      align-content: center;
      justify-content: row;
      height: 25px;
      margin-right: 16px;
      font-size: 16px;
      line-height: 25px;
      cursor: pointer;

      .icon {
        margin-right: 8px;
        font-size: 24px;
      }
    }
  }

  .svg-fill {
    fill: currentColor;
  }
}
