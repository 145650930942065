.export-toolbar {
  margin-top: 24px;

  .export-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .export-item {
      margin-left: 40px;
      text-align: center;
      cursor: pointer;

      .label {
        color: $grey-6;
        font-size: 12px;
        line-height: 26px;
        text-transform: uppercase;
      }
    }
  }
}
