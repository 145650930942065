.main-distance-form {
  .distance-form {
    .form-content-wrapper {
      &.distance-self-services-fields-form {
        width: 100%;
        max-width: 390px;
        padding: 16px;

        .about-self-services {
          font-size: 14px;
          text-align: center;
          margin-bottom: 32px;
        }

        .self-services-fields-wrapper {
          padding: 32px 0 48px 0;
        }
      }
    }
  }
}
