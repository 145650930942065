.sport-types {
  display: flex;
  align-content: center;
  padding: 24px 24px 32px;
  overflow: hidden;

  .main-list {
    width: 40%;
    margin-right: 24px;

    .toolbar {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      height: 40px;
      margin-bottom: 24px;

      .new-sport-type {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .icon {
          margin-right: 8px;
        }

        height: 40px;
        background-color: $sport-type-list-background-button;
        box-shadow: 0 2px 1px -1px $sport-type-list-shadow-button;
        font: {
          size: 14px;
          weight: 500;
        }
      }
    }
  }

  .list-wrapper {
    height: 91%;
    overflow: scroll;

    .list {
      .item {
        text-overflow: ellipsis;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        position: relative;
        height: 56px;
        margin-bottom: 4px;
        padding-left: 22px;
        padding-right: 30px;
        border-radius: 2px;
        background-color: $sport-type-item-background;
        font: {
          size: 16px;
          weight: 500;
        }
        line-height: 56px;
        user-select: none;
        cursor: pointer;

        .delete-icon {
          position: absolute;
          top: 6px;
          right: 8px;
          height: 24px;
          cursor: pointer;
          visibility: hidden;

          .svg-fill {
            fill: $sport-type-item-trash-color;
          }
        }

        &:hover {
          background-color: $sport-type-item-active;
        }

        &.active {
          background-color: $sport-type-item-active;

          .delete-icon {
            visibility: visible;
          }
        }
      }
    }
  }

  .spinner {
    margin: 0;
    .cubes-spinner {
      margin: 100px auto;
    }
  }
}

.drag-list-item-sport-type {
  padding-left: 22px;
  background-color: $sport-type-item-background-drag;
  box-shadow: 6px 6px 10px 1px $sport-type-item-shadow;

  font: {
    size: 16px;
    weight: 500;
  }

  line-height: 56px;
  list-style: none;

  .delete-icon {
    visibility: hidden;
  }
}
