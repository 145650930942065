.select-helper-container {
  .dropdown-arrow {
    position: absolute;
    right: 0;
    top: 22px;
    cursor: pointer;

    &.open {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  input {
    cursor: pointer;
  }

  &.form-control {
    &.select {
      .form-label {
        top: 0;
      }
    }
  }
}

.popover-container {
  width: 400px;

  .options-wrap {
    display: flex;
    width: 100%;

    .options-list {
      width: 47%;
      padding-top: 8px;
      padding-bottom: 8px;

      .list-option {
        display: flex;
        align-items: center;

        height: 48px;
        padding: 8px 12px;

        .dropdown-icon {
          height: 24px;
          width: 24px;

          margin-right: 12px;

          svg {
            background: none !important;
          }
        }

        &:hover {
          background-color: $white-2;
        }
      }
    }

    .helper-wrap {
      padding: 8px 12px;
      width: 53%;
      background-color: $white-2;

      .helper-block {
        width: 100%;
        min-height: 128px;

        img {
          margin-bottom: 8px;
        }
      }

      .text-wrap {
        padding-top: 16px;
        color: $grey-1;

        .title {
          font-size: 14px;
          font-weight: 500;
          padding-bottom: 8px;
        }

        .text {
          font-size: 12px;
          line-height: 1.5;
        }
      }
    }
  }
}
