.loading-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $white;

  .spinner {
    .cubes-spinner {
      margin: 0;
    }
  }
}
