.main-sport-type {
  width: 60%;

  .toolbar {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;

    height: 40px;
    margin-bottom: 24px;

    .sport-type-title {
      font-size: 20px;
    }
  }

  .sport-type-form {
    padding-top: 32px;
    padding-bottom: 32px;
    height: auto;
    min-height: 100%;

    background-color: $sport-type-form-background;

    .form-content-wrapper {
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: center;
      width: 60%;
      height: 100%;
      margin: 0 auto;

      .form-control {
        width: 100%;
      }

      .submit-button {
        min-width: 104px;
        margin-top: 6px;
        margin-left: auto;
        background-color: $sport-type-button-background;
        color: $sport-type-button-color;
        font: {
          size: 14px;
          weight: 500;
        }
      }

      .icon-field {
        width: 100%;

        .icon-label {
          .new-value {
            display: flex;
            line-height: 40px;
            cursor: pointer;
            align-items: center;

            .icon {
              margin-right: 16px;
              height: 42px;
            }

            .label {
              color: $sport-type-color-1;
              font-weight: 500;
              text-transform: uppercase;
              height: auto;
            }
          }

          .icon-container {
            display: flex;
            line-height: 40px;
            cursor: pointer;

            .preview {
              width: 40px;
              height: 40px;
              background-repeat: no-repeat;
              background-size: cover;
            }

            .loaded-icon {
              img {
                width: 40px;
                height: 40px;
              }
            }

            .label {
              position: relative;
              margin-left: 12px;

              .cancel-icon {
                position: absolute;
                top: -10px;
                right: -20px;
                color: $sport-type-color-2;
                cursor: pointer;
              }
            }
          }
        }

        .errors {
          color: $sport-type-color-3;
          font-size: 14px;
        }

        .icon-input {
          height: 0;
          visibility: hidden;
        }
      }
    }
  }
}
