.races-list {
  .component-list {
    .races_list_row {
      height: 70px;
    }
    .table-list {
      .cell {
        &.cell-name {
          text-decoration: underline;
          cursor: pointer;

          .label {
            color: $black-1;
            transition: opacity 400ms;
          }

          &:hover {
            .label {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
}
