.auth {
  height: 100vh;
  background: {
    image: url('../../img/auth-background.png');
    repeat: no-repeat;
    size: cover;
  }

  .background-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    background-color: rgba($grey-1, 0.86);
  }

  .logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 64px;
    text-align: center;
    .logo-wrapper-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 80px;
      margin-bottom: 12px;
      border-radius: 2px;
      background: $yellow-1;
      .logo {
        width: 47px;
        height: 36px;
        background: {
          image: url('../../img/svg/brand-new-logo.svg');
          repeat: no-repeat;
          size: cover;
        }
      }
    }
    span {
      color: $white;
      font: {
        size: 16px;
        style: italic;
        weight: 500;
      }
      line-height: 19px;
      letter-spacing: 8px;
      text-align: left;
    }
  }
}
