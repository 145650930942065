.CalendarMonth_table {
  .CalendarDay__blocked_calendar,
  .CalendarDay__blocked_calendar:active,
  .CalendarDay__blocked_calendar:hover {
    background: none;
    color: #4848482f;
    background: #fff;
    border: 1px solid #e4e7e7;
  }
  .CalendarDay__today {
    border-bottom: 2px solid #f9d026;
    font-weight: bold;
    color: #f9d026;
    color: $black;
    background: $grey-6;
    border: 1px solid $grey-8;
  }

  .CalendarDay__selected_span,
  .CalendarDay__selected_span:hover,
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    border: 1px solid $yellow-1;
    background-color: $yellow-1;
    opacity: 0.2;
    color: $grey-1;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background-color: $yellow-1;
    color: $grey-1;
    border: 1px solid $yellow-1;
  }

  .CalendarDay__today {
    background-color: $black;
    color: $white;
  }

  .CalendarDay {
    line-height: 38px;
  }
}

.CalendarMonth {
  .change-year {
    position: absolute;
    left: 229px;
    top: 0px;
    width: 142px;
    display: flex;
    justify-content: space-between;
    .year-value {
      margin-top: 5px;
    }
    .year-navigation-button {
      cursor: pointer;
      &:hover {
        rect {
          stroke: #a1a6a7;
        }
      }
    }
  }
}
.CalendarMonthGrid > .CalendarMonthGrid_month__horizontal.CalendarMonthGrid_month__horizontal_1:not(:nth-child(2)) .change-year {
  display: none;
}

.calendar-with-year {
  margin-top: 8px;
  .CalendarMonth_caption {
    margin-top: 24px;
  }
  .DayPicker_weekHeader {
    margin-top: 24px;
  }
  .DayPickerNavigation_button {
    margin-top: 24px;
  }
}

.DayPicker_transitionContainer {
  height: 336px !important;
}
