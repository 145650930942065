.auth {
  .form-control {
    min-width: 360px;
    min-height: 90px;
    font: {
      size: 16px;
    }
    text-align: center;

    .form-label {
      right: 0;
      color: $auth-form-group-text-1;
      font: {
        size: inherit;
      }
      line-height: 24px;
      text-align: center;
      transform-origin: top;

      &[data-shrink='true'] {
        color: $auth-form-group-text-2;
        font-size: 15px;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
        transform-origin: top;
      }
    }

    .field {
      height: initial;
      color: $white-2;
      font: {
        size: 16px;
      }
      line-height: 24px;
      text-align: center;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid $auth-form-group-text-2;
      border-radius: 4px;
    }

    .MuiFormLabel-focused-11 {
      color: $auth-text !important;
    }

    .underline {
      &::before {
        border-color: $auth-form-group-border-1 !important;
      }

      &::after {
        border-color: $auth-form-group-border-1;
      }
    }

    .filled-underline {
      &::before {
        border-color: $auth-form-group-border-2 !important;
      }

      &::after {
        border-color: $auth-form-group-border-2;
      }
    }

    .focused {
      color: $auth-text !important;
      .field {
        border: transparent;
      }
      &::after {
        border-color: $auth-text !important;
      }
    }

    .errors {
      font: {
        size: 12px;
      }
    }

    &.error {
      .form-label {
        color: $auth-form-group-error-1;
      }

      .field {
        color: $auth-form-group-text-3;
      }

      .underline {
        &::before {
          border-color: $auth-form-group-error-1 !important;
        }

        &::after {
          border-color: $auth-form-group-error-1 !important;
        }
      }

      .filled-underline {
        &::before {
          border-color: $auth-form-group-error-1 !important;
        }

        &::after {
          border-color: $auth-form-group-error-1;
        }
      }

      .focused {
        color: $auth-form-group-text-3 !important;

        &::after {
          border-color: $auth-form-group-error-1 !important;
        }
      }
    }
  }

  button.auth-submit {
    width: 100%;
    max-width: 420px;
    height: 48px;
    margin-top: 52px;
    border: transparent;
    border-radius: 4px;
    background-color: $login-button-hover;
    color: $login-button-text-1;
    box-shadow: none;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.25px;

    &:hover {
      border: solid 1px $login-button-1;
      background-color: $login-button-2;
      color: $login-button-1;
    }
  }
}
