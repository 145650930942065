.dnd-header {
  display: flex;
  align-items: center;
  height: 30px;
  padding-right: 11px;
  background-color: $dnd-header-background;
  cursor: pointer;

  .drag-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 10%;
  }

  .title {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 30px;

    width: 80%;
    height: 100%;
  }

  .controls-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    height: 100%;
    width: 20%;
    padding-left: 16px;

    .open-icon {
      cursor: pointer;
    }

    .item-controls {
      display: flex;
      align-items: center;
      height: 100%;
      cursor: default;

      .switch-field {
        margin-top: 0;
      }

      .delete-icon {
        margin-right: 8px;

        svg {
          height: 20px;
          width: 20px;
        }
      }
    }
  }

  .label {
    font: {
      weight: 500;
      size: 14px;
    }
    text-transform: uppercase;
  }

  .open-icon {
    color: $checkpoints-main-text;

    &:hover {
      color: rgba($checkpoints-main-text, 0.8);
    }
  }
}
