.form-wrap {
  width: 100%;

  &.unchecked {
    .legend-custom-field {
      color: $grey-6;
    }
  }

  .radio-wrap {
    display: flex;
    justify-content: space-between;
    width: 50%;

    .radio-label {
      font-size: 13px;

      &.unchecked {
        color: $grey-6;
      }
    }

    .radio-input {
      color: $grey-6;

      &.checked {
        color: $yellow-1;
      }
    }
  }

  .legend-custom-field {
    font-size: 16px;
    line-height: 1.5;
    color: $grey-1;

    &.error {
      color: $red-1;
    }
  }
}
