.html-preview {
  margin: 8px 0 32px;
  color: #262e35;
  font: {
    size: 16px;
    weight: 400;
  }
  line-height: 24px;
  word-wrap: break-word;

  a {
    color: $yellow-2;
    text-decoration: none;
  }

  p {
    margin-bottom: 10px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  strong {
    font-weight: bold;
  }
  pre {
    white-space: pre-wrap;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.17em;
  }

  h5 {
    font-size: 0.83em;
  }

  h6 {
    font-size: 0.67em;
  }

  em {
    font-style: italic;
  }

  sup,
  sub {
    font-size: smaller;
  }

  sup {
    vertical-align: super;
  }

  sub {
    vertical-align: sub;
  }

  code {
    background-color: #e8e8e8;
    border-radius: 3px;
    padding: 0.1rem 0.2rem;

    font-size: 14px;
    font-family: monospace;
  }

  blockquote {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;

    border-left: 2px solid #ccc;
    margin-left: 1.5rem;
    padding-left: 1rem;
  }

  ul,
  ol {
    list-style: inherit;
    padding-left: 30px;
    margin-bottom: 10px;
  }
}
