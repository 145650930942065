.main-distance-form {
  .distance-form {
    .form-content-wrapper {
      &.distance-class-fields-form {
        .separator {
          margin-top: 0;
        }

        .add-new {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 88px;
          margin-top: 24px;
          border: 1px solid $checkpoints-main-item-border;
          cursor: pointer;

          .icon {
            & > div {
              display: flex;
              align-items: center;

              svg {
                width: 24px;
                height: 24px;
              }
            }
          }

          .label {
            margin-left: 16px;
            color: $checkpoints-main-add-item;
            font-weight: 500;
            line-height: 24px;
            text-transform: uppercase;
          }
        }

        .distance-classes-list {
          .distance-class-item {
            margin-top: 24px;

            .remove-item-wrapper {
              display: flex;
              justify-content: flex-end;
              margin: 10px 0;

              .remove-item-label {
                color: $checkpoints-muted;
                font: {
                  size: 14px;
                  weight: 500;
                }
                letter-spacing: 0.5px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
