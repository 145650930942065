.main-racer-form {
  width: 456px;
  padding: 48px;

  .select-with-search {
    .form-control {
      .force-height {
        height: 53px;
      }
    }
  }

  .full-name {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .form-control {
      width: 47%;
    }
  }

  .racer-title {
    text-align: center;
    margin-bottom: 16px;

    font: {
      size: 20px;
      weight: 500;
    }
  }

  input {
    height: 24px;
    font-size: 14px;
  }

  label {
    font-size: 16px;
    color: $grey-6;
  }

  .form-control {
    margin-top: 7px;
  }

  .racer-form-element {
    position: relative;
    z-index: 1;

    .errors {
      font-size: 12px;
    }

    &.checkbox-wrap {
      display: flex;
      align-items: center;

      .field-root {
        margin-right: 5px;
        color: $grey-6;
      }
    }

    .label {
      font-size: 12px;
      color: $grey-1;
    }

    .field-root {
      font-size: 16px;

      &.select {
        height: 24px;
      }

      &.checked {
        color: $yellow-1;
      }
    }

    .tooltip-icon {
      color: $grey-6;

      svg {
        width: 24px;
      }
    }
  }

  .z-index-5 {
    z-index: 5;
  }

  .z-index-4 {
    z-index: 4;
  }

  .z-index-3 {
    z-index: 3;
  }

  .form-control {
    svg {
      color: $grey-6;
    }
  }

  .btn-group {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;

    .cancel {
      min-width: 89px;
      height: 36px;
      color: $grey-6;
      font: {
        size: 16px;
        weight: 500;
      }
    }

    .submit {
      min-width: 104px;
      height: 36px;
      border-radius: 2px;
      background-color: $yellow-1;
      color: $grey-1;
      font: {
        size: 16px;
        weight: 500;
      }
    }
  }
}

.checkbox-popper {
  width: 192px;

  .checkbox-tooltip {
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.4px;
  }
}
