.pagination {
  bottom: 48px !important;
  overflow: visible !important;
  .caption {
    font-size: 12px;
  }

  .choose-num {
    font-size: 12px;
    margin-right: 7px;
  }
}
