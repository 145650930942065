.min-tooltip-wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding-bottom: 10px;
}

.tooltip-wrap {
  display: flex;
  align-items: center;
  width: 100%;

  span {
    margin-right: 16px;
  }
}
