.main-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1300;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 47px;
  background-color: $footer-background;

  .copyright {
    padding-left: 24px;
    color: $footer-copyright;
    font-size: 12px;
    line-height: 47px;
    display: flex;
    justify-content: space-between;
  }

  .faq {
    color: white;
    margin-left: 24px;
  }

  .language-select {
    display: flex;
    align-content: center;

    .item {
      width: 24px;
      margin-right: 24px;
      opacity: 0.5;
      cursor: pointer;

      &:last-child {
        margin-right: 26px;
      }
    }

    .active {
      opacity: 1;
      cursor: initial;
    }
  }
}
