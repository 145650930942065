.toast-container {
  .error {
    & > div {
      background-color: $red-3;
    }
  }

  .message {
    word-break: break-word;
    font-size: 14px;
  }
}
