.main-distance-form {
  .distance-form {
    .form-content-wrapper {
      &.checkpoint-fields-form {
        max-width: 583px;
        padding-top: 16px;

        .subtitle {
          font: {
            size: 14px;
          }
          padding-top: 16px;
          padding-bottom: 37px;
          margin: auto;
          max-width: 368px;
          text-align: center;
          line-height: 1.71;
        }

        .hint {
          font: {
            size: 12px;
          }
          padding-top: 16px;
          margin: auto;
          max-width: 244px;
          text-align: center;
          color: $grey-6;
          line-height: 1.5;
        }

        .add-new {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 88px;
          margin-top: 24px;
          border: 1px solid $checkpoints-main-item-border;
          cursor: pointer;

          .icon {
            height: 24px;

            svg {
              width: 24px;
              height: 24px;
            }
          }

          .label {
            margin-left: 16px;
            color: $checkpoints-main-add-item;
            font-weight: 500;
            line-height: 24px;
            text-transform: uppercase;
          }
        }

        .checkpoint-list {
          margin-top: 24px;
          .checkpoint-item {
            margin-top: 24px;
            padding-bottom: 24px;
            border: 1px solid $checkpoints-main-item-border;

            &.error {
              .checkpoint-title {
                background-color: $checkpoints-title-error;

                .open-icon {
                  color: $black;
                }
              }
            }

            &.collapsed {
              padding-bottom: 0;
            }

            .checkpoint-form {
              padding: 0 24px;

              .assistant-controls {
                display: flex;
                justify-content: space-between;
                margin: 24px 0;
                color: $checkpoints-main-text;

                .icon {
                  color: $checkpoints-main-icon;
                  cursor: pointer;
                }

                .assistant-title {
                  &.error {
                    color: $red-2;
                  }
                }
              }

              .assistant-form {
                .description {
                  color: $checkpoints-main-text;
                  font: {
                    size: 12px;
                    weight: 300;
                  }
                }
              }
            }

            .checkpoint-title {
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 48px;
              padding: 0 24px;
              background-color: $checkpoints-title-background;

              cursor: pointer;

              .label {
                font: {
                  weight: 500;
                  size: 14px;
                }
                text-transform: uppercase;
              }

              .open-icon {
                color: $checkpoints-main-text;
              }
            }

            .remove-item-wrapper {
              display: flex;
              justify-content: flex-end;
              margin: 10px 0;
              padding-right: 24px;

              .remove-item-label {
                color: $checkpoints-muted;
                font: {
                  size: 14px;
                  weight: 500;
                }
                letter-spacing: 0.5px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
