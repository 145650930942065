.distance-refund-fields-form {
  width: 100%;
  padding: 32px 24px 24px 24px;

  .distance-refund-protect-content {
    display: flex;
    color: $grey-1;

    .distance-refund-protect-about {
      padding: 24px 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;

      background-color: $white-2;

      p {
        font-size: 12px;
        letter-spacing: 0px;
        line-height: 17px;
        text-align: center;

        span {
          font-weight: 600;
        }
      }

      h2 {
        margin: 32px 0 12px 0;
      }

      p:last-child {
        margin-top: 24px;
        margin-bottom: 16px;
        max-width: 80%;
      }

      img {
        max-height: 48px;
      }
    }

    .distance-refund-protect-conditions {
      padding: 36px 24px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 50%;
      border: 1px solid rgba($grey-1, 0.12);
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-left: none;
      font-size: 12px;

      li {
        font-size: 14px;
        display: flex;
        padding-bottom: 16px;

        > div {
          padding-right: 8px;
        }
      }

      h2 {
        margin-bottom: 24px;
      }

      a {
        padding-left: 8px;
        text-decoration: underline;
      }
    }

    h2 {
      color: $grey-1;
      font-size: 20px;
      font-weight: bold;
      min-height: 24px;
      letter-spacing: 0px;
      text-align: center;
    }
  }

  .distance-refund-field {
    .label {
      font-size: 16px;
      color: $grey-1;
    }
  }
}
