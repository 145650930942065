.not-found-container {
  height: 100%;
  width: 100%;

  background: url(../../img/not-found.png) no-repeat center;
  background-size: cover;

  .info-wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    width: 100%;
    height: 100%;
    padding-bottom: 77px;

    background-image: linear-gradient(to bottom, rgba(38, 46, 53, 0.42) 75%, $grey-1);
  }

  .info-block {
    display: flex;
    align-items: center;

    height: 200px;

    .text-wrap {
      padding-left: 24px;
    }

    .number {
      height: 100%;

      font-size: 120px;
      font-weight: 300;
      color: white;
      line-height: 200px;

      padding-right: 24px;
      border-right: 1px solid white;
    }

    .title {
      font-size: 32px;
      font-weight: 900;
      line-height: 1.25;
      color: white;

      width: 440px;
      padding-bottom: 16px;
    }

    .text-content {
      font-size: 24px;
      font-weight: 300;
      line-height: 1.38;
      color: white;

      padding-bottom: 16px;
    }

    .btn-redirect {
      padding: 20px 96px;
      border-radius: 2px;
      background-color: $races-submit-background;

      & > span {
        font: {
          size: 14px;
          weight: 500;
        }
      }
    }
  }
}
