.email-content-editor-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .editor-tooltip-wrapper {
    margin-top: 16px;
    width: 36px;
  }

  .text-editor {
    .editor-wrapper {
      min-height: 150px;
    }
  }
}
