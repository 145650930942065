.draft-item {
  max-width: 81px;
  width: 100%;
  padding: 5px 10px;

  &.awaiting {
    background: $yellow-3;
    p {
      color: $yellow-4;
    }
  }

  &.rejected {
    background: $red-4;
    p {
      color: $red-5;
    }
  }

  &.approved {
    background: $green-2;

    p {
      color: $green-3;
    }
  }

  &.draft {
    background: $grey-8;

    p {
      color: $grey-5;
    }
  }

  p {
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
  }
}

.race-status {
  display: flex;
  padding: 6px 20px 6px 5px;
  border-radius: 4px;
  text-transform: capitalize;

  .icon {
    height: 24px;
    margin-right: 8px;
  }

  &.awaiting {
    background: $yellow-6;
    p {
      color: $yellow-4;
    }
  }

  &.rejected {
    background: $red-7;
    p {
      color: $red-5;
    }
  }

  &.approved {
    background: $green-5;
    p {
      color: $green-3;
    }
  }

  p {
    font-size: 14px;
    white-space: nowrap;
    font-weight: 500;
    text-align: center;
    line-height: 1.7;
    letter-spacing: 0.25px;
  }
}
