.error-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  background-color: $grey-1;

  .info-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 600px;

    width: 685px;
    height: 685px;
  }

  .number {
    font-size: 120px;
    font-weight: 300;
    color: white;

    padding-bottom: 32px;
  }

  .title {
    font-size: 32px;
    font-weight: 900;
    line-height: 1.25;
    color: white;

    padding-bottom: 16px;
  }

  .text {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.5;
    text-align: center;
    color: white;

    width: 410px;

    & > a {
      font-weight: 500;
      color: $yellow-2;

      text-decoration: none;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
