.forgot-password {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .auth-submit {
    max-width: 288px !important;
  }

  .title {
    font-size: 16px;
    color: $forgot-header;
    letter-spacing: 2.7px;
    text-align: left;
    text-transform: uppercase;
  }

  .sub-title {
    margin-top: 8px;
    color: $forgot-header;
    font: {
      size: 14px;
    }
    letter-spacing: 0.23px;
    line-height: 25px;
    text-align: center;
  }

  .form-control.forgot {
    margin-top: 48px;
  }

  .sign-in {
    margin-top: 16px;
    color: $white;
    font: {
      size: 14px;
      weight: 700;
    }
    line-height: 20px;
    letter-spacing: 0.2px;
  }
}
