.container-info {
  display: flex;
  padding: 10px 0 24px 0;

  .icon {
    padding: 0 20px 0 0;
    margin: auto 0;
  }

  .info {
    font-size: 10pt;
    color: #ccc;
    margin: auto 0;
  }

  .label {
    font-size: 12pt;
    color: #ccc;
    margin: auto 0;
    padding-right: 10px;
  }
}
.main-wrapper .content-wrapper .main-content .details-start-lists .content.support-task-manager-box {
  overflow: auto;
}
