.results-import-errors-dialog {
  min-width: 640px;
  min-height: 208px;

  .alert-dialog-description {
    color: $black-2;

    .body-title {
      padding-bottom: 8px;
    }
  }

  button {
    color: $orange-1;
    font-size: 14px;
    text-transform: uppercase;
  }
}
