.account-info-list {
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 $organizers-detail-shadow;

  .info-title {
    min-height: 52px;
    padding: 16px;
    background-color: $account-info-list-title-background;
    font-size: 18px;
  }
  .with-tooltip {
    display: flex;
    justify-content: space-between;
  }

  .info-body {
    display: flex;
    flex-direction: column;
    padding: 16px;

    overflow-x: auto;

    .info-item {
      width: 100%;
      margin-bottom: 16px;
      padding: 12px;
      border: 2px solid $white-4;
      border-radius: 2px;

      .info-label {
        margin-bottom: 5px;
        color: $grey-5;
        font-size: 14px;
      }

      .info-value {
        font-size: 16px;
      }
    }
  }
}
