.menu-card {
  transform: translate(-10px, 75px);
}

.menu-card-item {
  min-width: 260px;
  width: 100%;

  p {
    font-size: 16px;
  }
}

.main-wrapper {
  .content-wrapper {
    .main-content {
      .details-start-lists {
        height: 100%;
        overflow: auto;

        .content {
          overflow: hidden;

          &.main-container-list {
            background-color: $white;

            &.start-list {
              padding: 0;
              display: flex;
              flex-direction: column;
              overflow: auto;

              .sticky-left {
                position: sticky;
                left: 0;
                z-index: 1;
              }

              .racer-no-content {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 24px;
                color: $grey-6;
              }
            }

            .component-list {
              padding-top: 0;
            }

            .filters-toolbar {
              margin: 16px;
            }

            //BREADCRUMPS
            .nav-toolbar {
              padding: 24px;
            }

            // NAVBAR
            .start-list-nav {
              display: flex;
              align-items: flex-end;

              padding: 15px;
              min-height: 35px;

              a {
                color: $black;
                font-size: 20px;
                line-height: 1;
                display: block;
                height: 30px;
                width: auto;
                padding: 3px 0;
                border-bottom: 4px solid $white;
                margin-right: 20px;

                &.active {
                  border-bottom: 4px solid $yellow-1;
                }
              }

              .nav-settings {
                display: flex;
                align-items: center;

                margin-left: auto;

                .icon-button-settings {
                  height: 30px;
                  margin-left: 12px;
                  padding: 0 12px;
                  color: $races-detail-muted-text2;

                  &:hover {
                    background: none;
                  }
                }

                .start-list-manage-squad-button {
                  padding: 16px 8px;
                  background-color: inherit;
                  border: 1px solid $sport-type-list-shadow-button;
                  border-radius: 2px;
                  color: $orange-1;
                  font-size: 14px;
                  font-weight: 500;
                  letter-spacing: 1.25px;
                  text-transform: uppercase;

                  &:hover {
                    background-color: rgba($yellow-1, 0.12);
                    cursor: pointer;
                  }
                }
              }
            }

            // TOOLBAR
            .toolbar-container {
              padding: 15px;
              background-color: $white-5;

              .racer-toolbar {
                padding-bottom: 0px;

                .details {
                  max-width: 55%;

                  :before {
                    border: 0;
                  }

                  :after {
                    border: 0;
                  }

                  .field-root {
                    div {
                      display: flex;
                      align-items: center;
                      font-size: 24px;
                      line-height: 1.5;
                      white-space: normal;

                      .select-label-options {
                        margin-left: 20px;
                        display: block;
                        background-color: $yellow-1;
                        padding: 5px;
                        font-size: 14px;
                        white-space: nowrap;
                      }

                      &:before {
                        border: 0;
                      }

                      &:after {
                        border: 0;
                      }
                    }
                  }

                  .distance-details {
                    .distance-info {
                      color: $grey-5;
                      font-size: 14px;
                      display: flex;

                      .date {
                        width: 92px;
                        height: 16px;
                        text-transform: uppercase;
                        margin-right: 40px;
                      }

                      .location {
                        display: flex;
                      }
                    }
                  }

                  .distance-prices {
                    .prices-list {
                      display: flex;

                      .price-item {
                        margin-right: 24px;

                        .price-date {
                          font-size: 14px;
                          color: $grey-5;
                        }

                        .price-value {
                          font-size: 24px;

                          .price-sek {
                            margin-left: 5px;
                            color: $grey-5;
                          }
                        }
                      }
                    }
                  }
                }

                .buttons {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;

                  .export-buttons {
                    margin-top: 72px;
                    color: $grey-6;

                    .list-buttons {
                      display: flex;

                      .button-item {
                        min-width: 64px;
                        margin-right: 12px;
                        text-align: center;
                        cursor: pointer;

                        &:last-child {
                          margin-right: 0;
                        }

                        .button-icon {
                          height: 24px;
                          margin: 13px 0 11px;
                          color: $grey-6;
                        }

                        .button-title {
                          color: $grey-6;
                          font-size: 12px;
                          font-weight: 500;
                          text-transform: uppercase;
                        }
                      }
                    }
                  }
                }
              }
            }

            .react-datepicker-wrapper {
              display: block;

              .react-datepicker__input-container {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

.racer-form-modal {
  height: 100%;
  width: 519px;
}

.squads-dialog {
  max-width: 770px !important;
}

.squads-form-modal {
  max-height: 491px;
  width: 770px;
  padding: 0 !important;
}

.start-list-one-distance {
  font-size: 16px;
  margin: 30px 0 40px;
}
