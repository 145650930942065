.nav-toolbar {
  display: flex;
  align-items: center;
  padding: 20px 0;
  color: $nav-toolbar-main-color;
  font: {
    size: 14px;
    weight: 500;
  }

  .separator {
    margin: 0 8px;
  }

  .nav-link {
    &:visited {
      color: $nav-toolbar-main-color;
    }

    &:active {
      color: $nav-toolbar-main-color;
    }

    &:hover {
      color: $nav-toolbar-main-color;
    }
  }
}
