.main-container-detail {
  padding: 0 24px 24px;

  .account-info-wrapper {
    padding: 24px;
    background-color: $white;
    box-shadow: 0 2px 4px 0 $organizers-detail-shadow;

    .account-info-title-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 28px;

      .icon {
        color: $grey-6;
        cursor: pointer;
      }

      .account-info-title {
        font-size: 20px;
      }
    }

    .account-info-body {
      display: flex;
      justify-content: space-between;

      .info-item {
        height: 60px;
        border-left: 1px solid $organizers-detail-border;
        padding-left: 23.5px;

        &:first-child {
          border: 0;
          padding-left: 0;
        }

        .info-label {
          margin-bottom: 16px;
          color: $grey-7;
          font-size: 14px;
          text-transform: uppercase;
        }

        .info-value {
          font-size: 18px;
        }
      }
    }
  }

  .payments-wrapper {
    margin-top: 18px;
    font-size: 12px;
    padding-top: 24px;
    background-color: $white;
    overflow: auto;
    box-shadow: 0 2px 4px 0 $organizers-detail-shadow;
    // 1593px is width of payments table
    .scroll-container {
      min-width: 1593px;
    }
    .payments-grid-row {
      border-bottom: 1px solid #e0e0e0;
      display: grid;
      grid-template-columns: 78px 310px 170px 225px 235px 283px 125px 88px 93px;
      min-width: 1593px;

      div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 16px;
        color: $grey-1;
      }

      &.heading {
        div {
          font-size: 12px;
          font-weight: normal;
          line-height: 15px;
          color: $grey-5;
        }
      }

      &.item {
        div {
          font-size: 13px;
          font-weight: 400;
          line-height: 1.43rem;
        }
      }
    }

    .payments-title {
      margin: 0 0 28px 16px;
      font-size: 20px;
      cursor: pointer;
    }

    .payments-title.selected {
      padding-bottom: 4px;
      border-bottom: 4px solid #f9d026;
    }

    .download-item {
      cursor: pointer;
    }

    .link-label {
      text-decoration: underline;
      cursor: pointer;
      color: $black-1;
      transition: opacity 400ms;
    }
  }
}
