@import './colors/project_default';
$green-1: #1e9437;
$green-2: #ecf6ed;
$green-3: #66bb6a;
$green-4: #57a257;
$green-5: #e4eee4;

$yellow-1: #f9d026;
$yellow-2: #fbc02d;
$yellow-3: #fdf5df;
$yellow-4: #efae00;
$yellow-5: rgba(239, 174, 0, 0.12);
$yellow-6: #f7f2dc;
$yellow-7: #595532;

$orange-1: #dda100;

$grey-1: #262e35;
$grey-2: #222328;
$grey-3: #30383f;
$grey-4: #252a2f;
$grey-5: #898f9a;
$grey-6: #a8aeb8;
$grey-7: #9b9b9b;
$grey-8: #f0f1f2;
$grey-9: #dbdbdb;
$grey-10: #888888;
$grey-11: #dedede;
$grey-12: #80868b;
$grey-13: #f9f9f9;
$grey-14: #dedede;
$grey-15: #696969;
$grey-16: #c0c0c0;

$red-1: #f44336;
$red-2: #d50000;
$red-3: #d32f2f;
$red-4: #fff1eb;
$red-5: #ff8a65;
$red-6: #e67b5c;
$red-7: #f7e8e4;
$red-8: #ce5659;

$white-2: #fafafa;
$white-3: #ededed;
$white-4: #f0f0f0;
$white-5: #f7f7f7;
$white-6: #f8f8fb;
$white-7: #dedede;

$black-1: rgba($black, 0.87);
$black-2: rgba($black, 0.54);

// Auth layout ---------------
$auth-text: $yellow-1;
$auth-form-group-text-1: rgba($white, 0.7);
$auth-form-group-text-2: rgba($white, 0.34);
$auth-form-group-text-3: $white;
$auth-form-group-border-1: rgba($white, 0.7);
$auth-form-group-border-2: rgba($white, 0.12);
$auth-form-group-error-1: $red-1;
// -----------------------

// Main layout ---------------
$main-layout-text-color: $grey-6;
$main-layout-border: rgba($black, 0.54);
$main-layout-errors: $red-2;
$main-layout-background: $white-2;
// ---------------------------

// Login component -----------
$login-button-hover: $yellow-1;
$login-button-1: rgba($white, 0.54);
$login-button-2: rgba($white, 0);
$login-button-text-1: $grey-1;
// ---------------------------

// Forgot password component -----------
$forgot-button-hover: $yellow-1;
$forgot-button-1: rgba($white, 0.54);
$forgot-button-2: rgba($white, 0);
$forgot-button-text-1: $grey-1;
$forgot-header: rgba($white, 0.87);
// ---------------------------

//Registration-----------------------
$registration-terms-text: rgba($white, 0.54);
$registration-link: $yellow-1;
$registration-box-text: $white;
// ---------------------------
// Button ---------------
$btn-green: $green-1;
$btn-green-active: $grey-2;
$btn-shadow: rgba($black, 0.53);
// -----------------------

// Footer ----------------
$footer-background: $grey-1;
$footer-copyright: rgba($white, 0.54);
// -----------------------

// Header ----------------
$header-background: $grey-1;
$header-text: $white;
// -----------------------

// Sidebar ---------------
$sidebar-background: $grey-3;
$sidebar-background-selected: $grey-4;
$sidebar-text: $white;
$sidebar-text: $white;
$sidebar-caret: $grey-5;
$sidebar-selected: $yellow-1;
// -----------------------

// Sport type list -------
$sport-type-list-background-button: $yellow-1;
$sport-type-list-shadow-button: rgba($black, 0.12);
$sport-type-item-background: $white;
$sport-type-item-active: rgba($yellow-1, 0.2);
$sport-type-item-trash-color: $yellow-1;
$sport-type-item-background-drag: rgba($white, 0.7);
$sport-type-item-shadow: rgba($grey-5, 0.2);
// -----------------------

// Sport type form -------
$sport-type-form-background: $white;
$sport-type-button-background: $yellow-1;
$sport-type-button-color: $white;
$sport-type-color-1: $orange-1;
$sport-type-color-2: $grey-6;
$sport-type-color-3: $red-2;
// -----------------------

// Spinner cubes ---------
$cubes-spinner-background: $yellow-1;
// -----------------------

// Races list ------------
$races-toolbar-background: $white;
$races-toolbar-item-background: $white-3;
$races-table-th: $grey-5;
$races-add-button-background: $yellow-1;
$races-add-button-shadow: rgba($black, 0.12);
$races-toolbar-active-item: $yellow-1;
// -----------------------

// Filters ---------------
$filter-select-active: rgba($yellow-1, 0.2);
$filter-reset-all: $yellow-1;
$filter-apply: $orange-1;
$filter-clear: $grey-5;
// -----------------------

// New race -------------
$races-upload-file-border: $white-4;
$races-upload-file-title: $grey-6;
$races-filter-clear: $grey-5;
$races-cancel-icon: $grey-5;
$text-editor-background: $white-2;
$main-icon-fill: $grey-5;
$races-submit-background: $yellow-1;
// -----------------------

// Nav toolbar -----------
$nav-toolbar-main-color: $grey-5;
$races-submit-background: $yellow-1;
// -----------------------

// Race detail -----------
$race-detail-toolbar-background: $white-5;
$races-detail-muted-text: $grey-5;
$races-detail-muted-text2: $grey-6;
$races-detail-highlighted-text: $yellow-1;
$races-detail-highlighted-text2: $orange-1;
$races-detail-item-border: rgba($black, 0.12);
$race-distance-dragging-shadow: rgba($black, 0.16);
$races-detail-add-new-border: $white-4;
$races-detail-header: $grey-1;
// -----------------------

// Stepper
$stepper-background: $white-2;
$stepper-error: $red-2;
$stepper-fill: $yellow-1;
$stepper-muted: $grey-5;
$stepper-muted: $grey-5;
$stepper-muted: $grey-5;

// New distance ----------
$distance-main-background: $white;
$distance-continue-background: $yellow-1;
$distance-continue-disabled: $grey-6;
$distance-main-title: $grey-1;
$distance-main-title: $grey-1;
$distance-muted-label: $grey-6;
$distance-separator: rgba(230, 230, 230, 0.25);
// -----------------------

// Chipiki------------------
$chip-main-backgroud: rgba($yellow-1, 0.54);
// -----------------------

// New Distance checkpoints
$checkpoints-main-item-border: $white-4;
$checkpoints-main-add-item: $orange-1;
$checkpoints-title-error: rgba($red-2, 0.25);
$checkpoints-main-text: $grey-6;
$checkpoints-main-icon: $yellow-1;
$checkpoints-title-background: $white-2;
$dnd-header-background: rgba($yellow-1, 0.2);
$checkpoints-muted: $grey-5;
// -----------------------

// Organizers detail
$organizers-detail-shadow: rgba($black, 0.14);
$organizers-detail-border: rgba($black, 0.27);
// -----------------------

// Account info list
$account-info-list-title-background: rgba($yellow-1, 0.54);
$organizers-detail-border: rgba($black, 0.27);
// -----------------------
