.checkbox-field {
  label {
    display: flex;
    align-items: flex-start;
    margin-right: 4px;
  }
  input {
    margin-bottom: 3px;
  }
  span:first-child {
    margin-top: -2px;
  }

  span:nth-child(2) {
    margin-top: 8px;
    font-size: 10px;
  }
}
