.aspect-ratio {
  position: relative;
  height: 0;

  .inner-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .image {
    background-size: cover !important;
  }
}
