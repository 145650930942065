.main-container-list {
  height: 100%;
  padding: 24px 24px 0;
  border-radius: 2px;

  .toolbar-container {
    padding: 24px 24px 0;
    background-color: $races-toolbar-background;

    .toolbar {
      display: flex;
      align-content: center;
      justify-content: space-between;

      .title {
        font-size: 20px;
      }

      .add-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        padding: 0 16px;
        background-color: $races-add-button-background;
        box-shadow: 0 2px 1px -1px $races-add-button-shadow;
        font: {
          size: 14px;
          weight: 500;
        }

        .icon {
          margin-right: 8px;
        }

        .button-title {
          width: 100%;
        }
      }
    }
  }

  .component-list {
    background-color: $white;
    box-shadow: none;

    .table-root {
      .table {
        //tr{
        //  height: 70px;
        //}
        .table-header {
          width: 150px;
        }
        .customer-table-header {
          width: unset;
        }

        .nowrap {
          white-space: nowrap;
        }

        // Dynamic table width
        // is not working properly now
        // &.scrollable {
        //   .table-header {
        //     width: 150px;
        //   }
        // }

        // &.not-scrollable {
        //   .table-header {
        //     width: 100%;
        //   }
        // }
      }
    }

    .table-header {
      color: $races-table-th;
      font-size: 12px;
      font-weight: 300;
      text-align: left;

      .label-wrapper {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        line-height: 16px;
        white-space: nowrap;

        .label {
          margin-right: 11px;
          line-height: 16px;
        }

        .icon {
          width: 16px;
          height: 16px;
          color: $races-table-th;
          opacity: 0;
          cursor: pointer;
          visibility: hidden;
          transition: transform 0.2s linear, opacity 0.2s linear, visibility 0.2s linear;
        }

        .filter-icon {
          width: 16px;
          height: 16px;
          margin-right: 11px;
          color: $races-table-th;
        }

        &.active {
          .icon {
            color: $orange-1;
            opacity: 1;
            visibility: visible;
          }
        }

        &.reverse {
          .icon {
            transform: rotate(180deg);
          }
        }
      }

      &.active {
        color: $orange-1;

        .label-wrapper {
          .filter-icon {
            color: $orange-1;
          }
        }
      }

      &:hover {
        .label-wrapper {
          .icon {
            opacity: 1;
            visibility: visible;
          }
        }
      }

      &.filter-item {
        cursor: pointer;
      }

      &.filter-item.disabled {
        cursor: default;
      }

      &.disabled {
        .label-wrapper {
          .filter-icon {
            display: none;
          }

          .label {
            margin-left: 0;
          }
        }
      }
    }

    .cell {
      font-size: 13px;

      .form-control {
        .field {
          font-size: 13px;

          input {
            &::placeholder {
              font-size: 13px;
            }
          }
        }

        .field-root {
          font-size: 13px;

          input {
            &::placeholder {
              font-size: 13px;
            }
          }
        }
      }

      &.clickable {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .cell-description {
      max-width: 300px;
      word-wrap: break-word;
    }

    .cell-distances {
      min-width: 300px;
    }

    .table-list {
      overflow: auto;
      box-shadow: none;
    }
  }
}

.icon {
  & > div {
    display: flex;
  }
}
