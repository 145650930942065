.payments-container {
  &.main-container-list {
    .component-list {
      .cell {
        .download-cell {
          display: flex;
          justify-content: center;
          width: 100%;

          svg {
            cursor: pointer;
          }
        }

        .label {
          text-decoration: underline;
          cursor: pointer;
          color: $black-1;
          transition: opacity 400ms;
        }
      }

      .cell-receipt {
        .label {
          margin-right: 0;
        }
      }

      .label-wrapper {
        .filter-icon,
        .icon {
          cursor: pointer;
        }
      }
    }
  }
}
