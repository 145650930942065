.registration {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .registration-terms,
  .registration-box {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }
  .registration-terms {
    position: relative;
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 100%;
    gap: 23px;

    & div {
      display: flex;
    }
    &-content {
      align-self: center;
    }
    &--text {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: rgba($registration-terms-text, 0.54);
    }
    &--link {
      color: $registration-link;
      text-transform: capitalize;
      font-size: 14px;
      line-height: 20px;
      font-weight: 700;
    }
    .switch-label {
      position: absolute;
      color: #f44336;
      font-size: 12px;
      left: 10px;
    }
    div:nth-child(1) .switch-label {
      top: 40px;
    }
    div:nth-child(2) .switch-label {
      top: 100px;
    }
  }

  .registration-box {
    align-self: flex-start;
    margin-top: 32px;

    &--text {
      color: $registration-box-text;
    }
    &--link {
      color: $registration-link;
      text-transform: capitalize;
      font-weight: 700;
    }
  }

  .MuiOutlinedInput-input:-webkit-autofill {
    box-shadow: 0 0 0 50px #282f35 inset;
    -webkit-box-shadow: 0 0 0 50px #282f35 inset;
    -webkit-text-fill-color: #a7adb7;
  }
  .book-demo-link {
    position: fixed;
    top: 30px;
    right: 50px;
  }
  .MuiFormControlLabel-root {
    margin: 0;
  }
}
