.spinner {
  margin: 0 200px;

  &.absolute-spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }

  .cubes-spinner {
    position: relative;
    width: 40px;
    height: 40px;
    margin: 350px auto;
    &.small {
      margin: 40px auto;
    }

    .cube1,
    .cube2 {
      position: absolute;
      top: 0;
      left: 0;
      width: 15px;
      height: 15px;
      background-color: $cubes-spinner-background;

      animation: sk-cubemove 1.8s infinite ease-in-out;
    }

    .cube2 {
      animation-delay: -0.9s;
    }

    @keyframes sk-cubemove {
      25% {
        transform: translateX(42px) rotate(-90deg) scale(0.5);
      }
      50% {
        transform: translateX(42px) translateY(42px) rotate(-180deg);
      }
      75% {
        transform: translateX(0) translateY(42px) rotate(-270deg) scale(0.5);
      }
      100% {
        transform: rotate(-360deg);
      }
    }

    @keyframes sk-cubemove {
      25% {
        transform: translateX(42px) rotate(-90deg) scale(0.5);
      }
      50% {
        transform: translateX(42px) translateY(42px) rotate(-179deg);
      }
      50.1% {
        transform: translateX(42px) translateY(42px) rotate(-180deg);
      }
      75% {
        transform: translateX(0) translateY(42px) rotate(-270deg) scale(0.5);
      }
      100% {
        transform: rotate(-360deg);
      }
    }
  }
}
