.editable-cell {
  &.loading {
    pointer-events: none;
  }

  .form-control {
    .field {
      font-size: 13px;

      input {
        &::placeholder {
          font-size: 13px;
        }
      }
    }

    .field-root {
      font-size: 13px;

      input {
        &::placeholder {
          font-size: 13px;
        }
      }
    }
  }
}
