.main-distance-form {
  .distance-form {
    .form-content-wrapper {
      &.custom-field-fields-form {
        .delete-icon {
          cursor: pointer;

          .svg-fill {
            fill: $checkpoints-main-text;
          }

          &:hover {
            .svg-fill {
              opacity: 0.8;
            }
          }
        }

        .add-new {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 88px;
          margin-top: 24px;
          border: 1px solid $checkpoints-main-item-border;
          cursor: pointer;

          .icon {
            height: 24px;

            svg {
              width: 24px;
              height: 24px;
            }
          }

          .label {
            margin-left: 16px;
            color: $checkpoints-main-add-item;
            font-weight: 500;
            line-height: 24px;
            text-transform: uppercase;
          }
        }

        .custom-field-list {
          padding-top: 24px;
          // for dnd wrapper
          padding-bottom: 1px;

          .custom-field-item {
            .dnd-header {
              .drag-icon {
                div {
                  div {
                    min-height: 40px;
                  }
                }
              }
            }
            margin-bottom: 24px;
            border: 1px solid $checkpoints-main-item-border;

            &.error {
              .custom-field-controls {
                background-color: $checkpoints-title-error;

                .open-icon {
                  color: $black;
                }
              }
            }

            &.collapsed {
              padding-bottom: 0;
            }

            .custom-fields {
              padding: 0 24px;

              .custom-field-label {
                font-size: 16px;
                font-weight: 500;
                color: $grey-1;
                padding: 24px 0 12px 0;
              }
            }

            .custom-field-form {
              padding: 24px 24px 0;
              background-color: white;

              .assistant-controls {
                display: flex;
                justify-content: space-between;
                margin: 24px 0;
                color: $checkpoints-main-text;

                .icon {
                  color: $checkpoints-main-icon;
                  cursor: pointer;
                }

                .assistant-title {
                  &.error {
                    color: $red-2;
                  }
                }
              }
            }

            .custom-field-controls {
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 48px;
              padding: 0 24px;
              background-color: $checkpoints-title-background;
              cursor: pointer;

              .label {
                font: {
                  weight: 500;
                  size: 14px;
                }
                text-transform: uppercase;
              }

              .open-icon {
                color: $checkpoints-main-text;

                &:hover {
                  color: rgba($checkpoints-main-text, 0.8);
                }
              }

              .item-controls {
                display: flex;

                .delete-icon {
                  height: 20px;
                  width: 20px;
                  margin-right: 16px;
                }
              }
            }

            .item-toolbar {
              .add-new-custom-field-item {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 16px;
                font-size: 12px;
                cursor: pointer;

                .add-icon {
                  margin-right: 12px;
                  color: $checkpoints-main-icon;
                }
              }
            }

            .item-toolbar-extra {
              margin-top: 16px;
            }

            .item-toolbar-refund {
              margin-top: 16px;

              .add-new-custom-field-item {
                justify-content: start;
              }
            }

            .field-values-list {
              padding-top: 24px;
              padding-bottom: 16px;

              .value-item {
                margin-bottom: 8px;

                .value-header {
                  background-color: $white-4;
                }

                &.collapsed {
                  padding-bottom: 0;
                }

                .item-form {
                  padding: 24px;
                  background-color: white;

                  .item-controls {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 24px;
                    font: {
                      size: 14px;
                      weight: 500;
                    }
                    text-transform: uppercase;
                  }

                  .number-input-wrap {
                    display: flex;
                    justify-content: space-between;

                    width: 100%;
                  }

                  .number-input {
                    width: 47%;
                  }
                }
              }
            }

            .remove-item-wrapper {
              display: flex;
              justify-content: flex-end;
              margin: 10px 0;
              padding-right: 24px;

              .remove-item-label {
                color: $checkpoints-muted;
                font: {
                  size: 14px;
                  weight: 500;
                }
                letter-spacing: 0.5px;
                cursor: pointer;
              }
            }
          }
        }

        .btn-group {
          margin-top: 0;
        }
      }
    }
  }
}
