.filters-toolbar {
  display: flex;
  flex-flow: row wrap;
  list-style: none;

  &:empty {
    min-height: 16px;
    margin: 0 !important;
  }

  .filter-item {
    margin-right: 12px;
  }
}
