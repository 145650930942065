.main-squads-form {
  .squads-title {
    padding: 16px;
    border-bottom: 1px solid $races-detail-item-border;
    display: flex;
    justify-content: space-between;
    align-items: center;

    font: {
      size: 20px;
      weight: 500;
    }

    button {
      font-size: 16px;
    }
  }

  .form-control {
    svg {
      color: $grey-6;
    }
  }

  .btn-group {
    margin-top: 45px;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid $races-detail-item-border;
    padding: 16px 24px;

    .close {
      width: 96px;
      height: 36px;
      margin-left: 16px;
      border-radius: 2px;
      background-color: $yellow-1;
      color: $grey-1;
      font: {
        size: 16px;
        weight: 500;
      }
    }
  }

  .squads-list {
    & > div {
      box-shadow: none;
    }

    .squads-scrollable-wrapper {
      max-height: 300px;
      overflow: auto;

      .loader {
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .squads-header {
      color: $grey-6;
      font-size: 14px;
    }

    tbody {
      tr {
        background-color: rgba($black, 0.04);
      }

      tr:nth-child(2n) {
        background-color: white;
      }
    }

    svg {
      color: $grey-6;
    }

    .controls-block {
      text-align: end;

      svg:hover {
        cursor: pointer;
      }
    }

    .controls-menu .control-item .text {
      padding: 0 64px 0 0;
    }

    .input-wrap {
      width: 100%;
    }
  }

  .squads-no-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: $grey-6;
    margin-top: 48px;
  }
}
