@import '../utilities/color';

.standart-table::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.standart-table::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
.standart-table::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}
.standart-table::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}
.standart-table::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.9);
}
.standart-table {
  // Simple

  // border-collapse: collapse;
  // width: 100%;
  // height: 100%;
  // overflow: auto;
  .MuiTablePagination-spacer {
    flex: none !important;
  }
  table {
    width: 100%;
    border-collapse: separate;
    padding: 0 20px;
    th {
      color: rgb(168, 174, 184);
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      white-space: nowrap;
      padding: 0 10px;
      text-align: left;
      &:first-child {
        padding-left: 0;
      }
    }
    td {
      white-space: nowrap;
      vertical-align: top;
      padding: 0 10px;
    }
  }
  tr {
    max-height: 52px;
  }

  .header-cell {
    font-size: 14px;
    padding: 20px 16px;
    text-align: left;
    color: $grey-6;
    background-color: white;
  }

  .row {
    &:nth-child(odd) {
      background-color: #f5f5f5;
    }
    &:hover {
      background-color: lighten(color('default', 'light-gray'), 20%);
    }
  }

  .name-link {
    color: rgba(0, 0, 0, 0.87);
    text-decoration: underline;
  }

  .cell {
    padding: 18px 16px;
    font-size: 14px;
    vertical-align: middle;
  }
}

.sync-table {
  th {
    padding: 0 16px 17px !important;
  }
}
