.text-editor {
  .editor-wrapper {
    min-height: 300px;

    .public-DraftStyleDefault-block {
      span {
        max-width: 100px;
        max-height: 100px;
        background-repeat: round;
      }
    }
  }
}

.tinymce-6-lines .tox {
  min-height: calc(124px + (6 * 22.4px));
}
