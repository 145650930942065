.main-organizer-form {
  width: 456px;
  padding: 48px;
  color: $grey-6;

  h1 {
    margin: 0 auto;
    margin-bottom: 20px;
    color: $black;
    font: {
      size: 20px;
      weight: 500;
    }
    text-align: center;
  }

  input {
    width: 100%;
    height: 24px;
    font-size: 16px;
  }

  .underline {
    &::after {
      border-color: $yellow-1;
    }
  }

  .focused {
    &::after {
      border-color: $auth-text;
    }
  }

  .form-label {
    color: $grey-6;
    font-size: 16px;
  }

  .field-root {
    color: $black;
    font-size: 16px;
  }

  .form-control {
    margin-top: 7px;
  }

  .control-btn {
    display: flex;
    justify-content: space-between;

    .submit {
      min-width: 104px;
      height: 36px;
      border-radius: 2px;
      background-color: $yellow-1;
      color: #262e35;
      font-size: 14px;
      span {
        height: 16px;
        color: $white;
        font-size: 14px;
        letter-spacing: 0.5px;
        text-align: center;
      }
    }

    .cancel {
      min-width: 57px;
      color: $grey-5;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.5px;
      text-align: center;
    }
  }
}
