.results-table {
  .MuiTableCell-root .MuiSelect-root {
    background: $white !important;
  }

  .MuiInputBase-input {
    border: 1px solid #c4c4c4 !important;
    border-radius: 4px !important;
    padding: 18.5px 14px;
  }

  .MuiInputBase-input:hover {
    border: 1px solid $black !important;
    border-radius: 4px !important;
    padding: 18.5px 14px;
  }

  input.MuiInputBase-input:focus {
    border: 2px solid $yellow-1 !important;
    border-radius: 4px !important;
    padding: 18.5px 14px;
  }

  div.MuiSelect-select:active {
    border: 2px solid $yellow-1 !important;
    border-radius: 4px !important;
    padding: 18.5px 14px;
  }

  .MuiInput-underline:after {
    border-bottom: 0px;
  }

  .MuiInput-underline:before {
    border-bottom: 0px;
  }

  .MuiInput-underline:hover {
    border-bottom: 0px;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0px;
  }
}
