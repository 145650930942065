.drop-file-field {
  svg {
    background: none !important;
  }

  .dropzone-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 2px;
    border: 2px dashed #a8aeb8;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
    font-size: 14px;
  }

  .active-container {
    border-color: #2196f3;
  }

  .accept-container {
    border-color: #00e676;
  }

  .reject-container {
    border-color: #ff1744;
  }

  .download-file {
    margin-bottom: 16px;

    .icon-field {
      min-height: 0;
      height: 56px;
      background-color: rgba($white-4, 0.64);

      .aspect-ratio {
        padding-bottom: 56px !important;
      }

      #race-icon {
        visibility: hidden;
        height: 0;
      }

      .edit-icon {
        position: absolute;
        right: 12px;
        top: 16px;
        fill: $grey-6;
      }

      .edit-icon:hover {
        cursor: pointer;
      }

      .image {
        height: 40px;
        background-size: contain !important;
        background-repeat: no-repeat;
        margin: 8px 8px 8px 16px;
        min-height: 0;
      }

      .file-name {
        display: flex;
        flex-flow: nowrap;
        align-items: center;
        height: 56px;
        color: $grey-1;
        font-size: 14px;
        margin-left: 88px;
        margin-right: 44px;
        white-space: break-spaces;
      }
    }
  }

  .extra-download-button {
    text-decoration: underline;
    border: none;
    background: initial;
    color: #fbc02d;
  }

  .extra-download-button:hover {
    cursor: pointer;
  }

  .errors {
    color: $red-1;
  }
}
