@import '../utilities/color';
@import '../utilities/fonts_var';

// Form elements style
input[type='text'],
input[type='tel'],
input[type='email'],
input[type='password'],
input[type='search'],
textarea {
  outline: none;
  text-align: left;

  &::-webkit-input-placeholder {
    //color: $form-placeholder;
    font: {
      size: 16px;
    }
  }

  &::-moz-placeholder {
    //color: $form-placeholder;
    font: {
      size: 16px;
    }
  }

  &:-ms-input-placeholder {
    //color: $form-placeholder;
    font: {
      size: 16px;
    }
  }
}

textarea {
  resize: none;
}

input {
  height: 50px;
}

.input-wrap {
  .info-helper {
    position: absolute;
    top: 22px;
    right: 12px; // for icons located on right side of date picker
    cursor: pointer;

    &.with-background {
      background-color: white;
    }
  }
}
